import { Box, Stack, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { SearchWidget } from "../../containers/Search/Widget";
import { CenteredContent } from "../CenteredContent";

export function ErrorSearch() {
  return (
    <Box>
      <Text variant="h4">
        <FormattedMessage defaultMessage="Vous recherchez un hébergement ? Trouvez la location de vos rêves sur Clévacances :" />
      </Text>

      <Stack mt="1rem">
        <CenteredContent maxWidth="980px">
          <SearchWidget
            withFilters={false}
            withSort={false}
            withDetailledSubmitButton={true}
          />
        </CenteredContent>
      </Stack>
    </Box>
  );
}

import { defineMessage } from "react-intl";

export const SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE = 0;
export const SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_1 = 1;
export const SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_2 = 2;
export const SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_3 = 3;
export const SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_7 = 7;

/** @type {import("../types/Search").SearchDatesPreciseMode[]} */
export const SEARCH_DATES_PRECISE_MODES_VALUES = [
  SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE,
  SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_1,
  SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_2,
  SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_3,
  SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_7,
];

/** @type {Record<import("../types/Search").SearchDatesPreciseMode, {value: import("../types/Search").SearchDatesPreciseMode, label: import("react-intl").MessageDescriptor, offset: number}>} */
export const SEARCH_DATES_PRECISE_MODES = {
  [SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE]: {
    value: SEARCH_DATES_PRECISE_MODES_VALUE_PRECISE,
    offset: 0, // represents the number of days to add or subtract from the checkin & checkout dates
    label: defineMessage({
      defaultMessage: "Dates précises",
    }),
  },
  [SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_1]: {
    value: SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_1,
    offset: 1, // represents the number of days to add or subtract from the checkin & checkout dates
    label: defineMessage({
      defaultMessage: "± 1 jour",
    }),
  },
  [SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_2]: {
    value: SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_2,
    offset: 2, // represents the number of days to add or subtract from the checkin & checkout dates
    label: defineMessage({
      defaultMessage: "± 2 jours",
    }),
  },
  [SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_3]: {
    value: SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_3,
    offset: 3, // represents the number of days to add or subtract from the checkin & checkout dates
    label: defineMessage({
      defaultMessage: "± 3 jours",
    }),
  },
  [SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_7]: {
    value: SEARCH_DATES_PRECISE_MODES_VALUE_OFFSET_7,
    offset: 7, // represents the number of days to add or subtract from the checkin & checkout dates
    label: defineMessage({
      defaultMessage: "± 7 jours",
    }),
  },
};

export const SEARCH_DATES_PRECISE_MODES_LIST = Object.values(
  SEARCH_DATES_PRECISE_MODES,
);

export const SEARCH_DATES_MODES_VALUE_PRECISE = "precise";
export const SEARCH_DATES_MODES_VALUE_FLEXIBLE = "flexible";

/** @type {import("../types/Search").SearchDatesMode[]} */
export const SEARCH_DATES_MODES_VALUES = [
  SEARCH_DATES_MODES_VALUE_PRECISE,
  SEARCH_DATES_MODES_VALUE_FLEXIBLE,
];

/** @type {Record<import("../types/Search").SearchDatesMode, {value: import("../types/Search").SearchDatesMode}>} */
export const SEARCH_DATES_MODES = {
  [SEARCH_DATES_MODES_VALUE_PRECISE]: {
    value: SEARCH_DATES_MODES_VALUE_PRECISE,
  },
  [SEARCH_DATES_MODES_VALUE_FLEXIBLE]: {
    value: SEARCH_DATES_MODES_VALUE_FLEXIBLE,
  },
};

export const SEARCH_RANKING_VALUE_0 = "";
export const SEARCH_RANKING_VALUE_1 = 1;
export const SEARCH_RANKING_VALUE_2 = 2;
export const SEARCH_RANKING_VALUE_3 = 3;
export const SEARCH_RANKING_VALUE_4 = 4;
export const SEARCH_RANKING_VALUE_5 = 5;

/** @type {import("../types/Search").SearchRanking[]} */
export const SEARCH_RANKING_VALUES = [
  SEARCH_RANKING_VALUE_0,
  SEARCH_RANKING_VALUE_1,
  SEARCH_RANKING_VALUE_2,
  SEARCH_RANKING_VALUE_3,
  SEARCH_RANKING_VALUE_4,
  SEARCH_RANKING_VALUE_5,
];

/** @type {Record<import("../types/Search").SearchRanking, {value: import("../types/Search").SearchRanking}>} */
export const SEARCH_RANKING = {
  [SEARCH_RANKING_VALUE_0]: {
    value: SEARCH_RANKING_VALUE_0,
  },
  [SEARCH_RANKING_VALUE_1]: {
    value: SEARCH_RANKING_VALUE_1,
  },
  [SEARCH_RANKING_VALUE_2]: {
    value: SEARCH_RANKING_VALUE_2,
  },
  [SEARCH_RANKING_VALUE_3]: {
    value: SEARCH_RANKING_VALUE_3,
  },
  [SEARCH_RANKING_VALUE_4]: {
    value: SEARCH_RANKING_VALUE_4,
  },
  [SEARCH_RANKING_VALUE_5]: {
    value: SEARCH_RANKING_VALUE_5,
  },
};

export const SEARCH_DURATIONS_VALUE_2_NIGHTS = "2_nights";
export const SEARCH_DURATIONS_VALUE_3_NIGHTS = "3_nights";
export const SEARCH_DURATIONS_VALUE_4_NIGHTS = "4_nights";
export const SEARCH_DURATIONS_VALUE_1_WEEK = "1_week";
export const SEARCH_DURATIONS_VALUE_2_WEEKS = "2_weeks";
export const SEARCH_DURATIONS_VALUE_3_WEEKS = "3_weeks";
export const SEARCH_DURATIONS_VALUE_1_MONTH = "1_month";

/** @type {import("../types/Search").SearchDuration[]} */
export const SEARCH_DURATIONS_VALUES = [
  SEARCH_DURATIONS_VALUE_2_NIGHTS,
  SEARCH_DURATIONS_VALUE_3_NIGHTS,
  SEARCH_DURATIONS_VALUE_4_NIGHTS,
  SEARCH_DURATIONS_VALUE_1_WEEK,
  SEARCH_DURATIONS_VALUE_2_WEEKS,
  SEARCH_DURATIONS_VALUE_3_WEEKS,
  SEARCH_DURATIONS_VALUE_1_MONTH,
];

/** @type {Record<import("../types/Search").SearchDuration, {value: import("../types/Search").SearchDuration, minDuration: number, maxDuration: number, label: import("react-intl").MessageDescriptor, durationType: "night" | "week"}>} */
export const SEARCH_DURATIONS = {
  [SEARCH_DURATIONS_VALUE_2_NIGHTS]: {
    value: SEARCH_DURATIONS_VALUE_2_NIGHTS,
    minDuration: 1,
    maxDuration: 2,
    label: defineMessage({
      defaultMessage: "2 nuits",
    }),
    durationType: "night",
  },
  [SEARCH_DURATIONS_VALUE_3_NIGHTS]: {
    value: SEARCH_DURATIONS_VALUE_3_NIGHTS,
    minDuration: 3,
    maxDuration: 3,
    label: defineMessage({
      defaultMessage: "3 nuits",
    }),
    durationType: "night",
  },
  [SEARCH_DURATIONS_VALUE_4_NIGHTS]: {
    value: SEARCH_DURATIONS_VALUE_4_NIGHTS,
    minDuration: 4,
    maxDuration: 4,
    label: defineMessage({
      defaultMessage: "4 nuits",
    }),
    durationType: "night",
  },
  [SEARCH_DURATIONS_VALUE_1_WEEK]: {
    value: SEARCH_DURATIONS_VALUE_1_WEEK,
    minDuration: 7,
    maxDuration: 9,
    label: defineMessage({
      defaultMessage: "1 semaine",
    }),
    durationType: "week",
  },
  [SEARCH_DURATIONS_VALUE_2_WEEKS]: {
    value: SEARCH_DURATIONS_VALUE_2_WEEKS,
    minDuration: 14,
    maxDuration: 16,
    label: defineMessage({
      defaultMessage: "2 semaines",
    }),
    durationType: "week",
  },
  [SEARCH_DURATIONS_VALUE_3_WEEKS]: {
    value: SEARCH_DURATIONS_VALUE_3_WEEKS,
    minDuration: 21,
    maxDuration: 23,
    label: defineMessage({
      defaultMessage: "3 semaines",
    }),
    durationType: "week",
  },
  [SEARCH_DURATIONS_VALUE_1_MONTH]: {
    value: SEARCH_DURATIONS_VALUE_1_MONTH,
    minDuration: 22,
    maxDuration: 30,
    label: defineMessage({
      defaultMessage: "1 mois",
    }),
    durationType: "week",
  },
};

export const SEARCH_DURATIONS_LIST = Object.values(SEARCH_DURATIONS);

export const SEARCH_SORT_VALUE_PRICE_BEST = "best";
export const SEARCH_SORT_VALUE_PRICE_ASC = "price_asc";
export const SEARCH_SORT_VALUE_PRICE_DESC = "price_desc";
export const SEARCH_SORT_VALUE_AVERAGE_RATE_DESC = "average_rate_desc";
export const SEARCH_SORT_VALUE_CAPACITY_ASC = "capacity_asc";
export const SEARCH_SORT_VALUE_CAPACITY_DESC = "capacity_desc";
export const SEARCH_SORT_VALUE_LABEL_RANKING_DESC = "label_ranking_desc";

/** @type {Record<import("../types/Search").SearchSort, {value: import("../types/Search").SearchSort, label: import("react-intl").MessageDescriptor}>} */
export const SEARCH_SORTS = {
  [SEARCH_SORT_VALUE_PRICE_BEST]: {
    value: SEARCH_SORT_VALUE_PRICE_BEST,
    label: defineMessage({
      defaultMessage: "pertinence",
    }),
  },
  [SEARCH_SORT_VALUE_AVERAGE_RATE_DESC]: {
    value: SEARCH_SORT_VALUE_AVERAGE_RATE_DESC,
    label: defineMessage({
      defaultMessage: "les mieux notés",
    }),
  },
  [SEARCH_SORT_VALUE_PRICE_ASC]: {
    value: SEARCH_SORT_VALUE_PRICE_ASC,
    label: defineMessage({
      defaultMessage: "les moins chers",
    }),
  },
  [SEARCH_SORT_VALUE_PRICE_DESC]: {
    value: SEARCH_SORT_VALUE_PRICE_DESC,
    label: defineMessage({
      defaultMessage: "les plus chers",
    }),
  },
  [SEARCH_SORT_VALUE_LABEL_RANKING_DESC]: {
    value: SEARCH_SORT_VALUE_LABEL_RANKING_DESC,
    label: defineMessage({
      defaultMessage: "les plus confortables",
    }),
  },
  [SEARCH_SORT_VALUE_CAPACITY_ASC]: {
    value: SEARCH_SORT_VALUE_CAPACITY_ASC,
    label: defineMessage({
      defaultMessage: "plus petites capacités",
    }),
  },
  [SEARCH_SORT_VALUE_CAPACITY_DESC]: {
    value: SEARCH_SORT_VALUE_CAPACITY_DESC,
    label: defineMessage({
      defaultMessage: "plus grandes capacités",
    }),
  },
};

export const SEARCH_SORTS_LIST = Object.values(SEARCH_SORTS);

export const SEARCH_SORTS_VALUES = Object.values(SEARCH_SORTS).map(
  (sort) => sort.value,
);

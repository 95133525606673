import { Box, Button, IconButton, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { SelectDrawer } from "../../../components/Form/SelectDrawer";
import { Icon } from "@raiden/library-ui/components/Icon";
import { SEARCH_SORTS, SEARCH_SORTS_LIST } from "../../../constants/Search";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FormControllerRenderParams<import("./Handler").SearchFormValues, "sort">} field
 */
/**
 * @param {Props} props
 */
export function SortSelect({ field }) {
  const intl = useIntl();

  const sortOptions = useMemo(() => {
    /** @type {import("../../../components/Form/SelectDrawer").SelectDrawerOption[]} */
    const options = SEARCH_SORTS_LIST.map((sort) => ({
      value: sort.value,
      name: intl.formatMessage(sort.label),
    }));

    return options;
  }, [intl]);

  return (
    <Box
      display="inline-block"
      backgroundColor="#fff"
      borderRadius={{ base: "full", lg: "10px" }}>
      <SelectDrawer
        title={intl.formatMessage({ defaultMessage: "Trier" })}
        options={sortOptions}
        allowUnselect={false}
        popoverPlacement="bottom-start"
        {...field}>
        <>
          <Button
            variant="outline"
            display={{ base: "none", lg: "flex" }}
            minHeight="44px"
            rightIcon={
              <Icon icon="ms_expand_more" size="21px" color="gray.500" />
            }>
            <Text as="span" variant="text-input">
              {intl.formatMessage(
                {
                  defaultMessage: "Trier par {value}",
                },
                {
                  value: intl.formatMessage(SEARCH_SORTS[field.value].label),
                },
              )}
            </Text>
          </Button>

          <IconButton
            variant="outline"
            aria-label={intl.formatMessage(
              {
                defaultMessage: "Trier par {value}",
              },
              {
                value: intl.formatMessage(SEARCH_SORTS[field.value].label),
              },
            )}
            borderRadius="full"
            display={{ base: "flex", lg: "none" }}
            height={{ base: "48px", md: "44px" }}
            width={{ base: "48px", md: "44px" }}
            icon={<Icon icon="ms_sort" size="20px" color="gray.500" />}
          />
        </>
      </SelectDrawer>
    </Box>
  );
}

import {
  Box,
  Divider,
  PopoverBody,
  Progress,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import useSWRInfinite from "swr/infinite";
import ObserverRHF from "../../../components/ReactHookForm/ObserverRHF";
import { SWR_CONFIG_FOR_STATIC_DATA } from "../../../constants/swr/swrConfigForStaticData";
import useApiFetcher from "../../../hooks/useApiFetcher";
import { FormAutoSubmit } from "../AutoSubmit";
import SearchInput from "./SearchInput";

/**
 * @template {import(".").AutocompleteItemValue} T
 * @typedef {object} Props
 * @property {import(".").AutocompleteValue} value the value of the form control, can be an array or a single value
 * @property {import(".").AutocompleteOnChange} onChange
 * @property {import(".").AutocompleteItemValue[]} values the value of the form control, normalized to an array
 * @property {import(".").AutocompleteRenderOption} renderOption
 * @property {import(".").AutocompleteResolveItemName} resolveItemName
 * @property {import(".").AutocompleteResolveItemValue<T>} resolveItemValue
 * @property {import(".").AutocompleteFilterItemByValue} [filterItemValue]
 * @property {import("react").MutableRefObject<HTMLInputElement | null>} inputRef
 * @property {(params: {page: number, fields: {}}) => string} generateUrl
 * @property {import("react").Dispatch<import("react").SetStateAction<{}>>} setValueItemMap
 * @property {() => void} onClose
 * @property {import(".").AutocompleteResolveItemByValue} resolveItemByValue
 * @property {import(".").AutocompleteItemValue[]} persistentValues
 * @property {import("react").Dispatch<import("react").SetStateAction<import(".").AutocompleteItemValue[]>>} setPersistentValues
 * @property {import(".").AutocompleteRemoveValue} removeValue
 * @property {boolean} isMultiple
 * @property {number} minSearchTermLength
 */
/**
 * @template {import(".").AutocompleteItemValue} T
 * @param {Props<T>} props
 */
function AutocompleteBody({
  value: _value,
  onChange,
  values,
  renderOption,
  resolveItemName,
  resolveItemValue,
  inputRef,
  generateUrl,
  setValueItemMap,
  onClose,
  resolveItemByValue,
  persistentValues,
  setPersistentValues,
  removeValue,
  isMultiple,
  minSearchTermLength,
  filterItemValue,
}) {
  const apiFetcher = useApiFetcher();

  const backgroundColor = useColorModeValue("white", "gray.700");

  const refPopoverBody = useRef(/** @type {HTMLDivElement | null} */ (null));

  const [submittedFields, setSubmittedFields] = useState({ term: "" });

  const getKey = useCallback(
    (_page) => {
      const page = _page + 1;
      if (submittedFields.term.length < minSearchTermLength) {
        return null;
      }
      return generateUrl({ page, fields: submittedFields });
    },
    [generateUrl, minSearchTermLength, submittedFields],
  );

  /** @type {import("swr/infinite").SWRInfiniteResponse<import("../../../types/Api/ApiResponse").ApiResponse<any>>} */
  // @ts-ignore
  const swrInfiniteResponse = useSWRInfinite(getKey, apiFetcher, {
    revalidateFirstPage: false,
    keepPreviousData: true,
    ...SWR_CONFIG_FOR_STATIC_DATA,
  });

  const isFullyLoaded = useMemo(() => {
    const pages = swrInfiniteResponse.data;
    if (pages) {
      const lastPage = pages[pages.length - 1];
      if (!lastPage) {
        return false;
      }
      // we fallback on page 1 and last_page 1 if the meta is not present
      const isLastestPage =
        (lastPage?.meta?.current_page ?? 1) >= (lastPage?.meta?.last_page ?? 1);

      return isLastestPage;
    }
    return false;
  }, [swrInfiniteResponse.data]);

  // forces the update of the swrInfiniteResponse on mount
  const refIsMounted = useRef(false);
  if (!refIsMounted.current) {
    swrInfiniteResponse.mutate();
    refIsMounted.current = true;
  }

  // resets the size of the swrInfiniteResponse when the component unmounts
  useEffect(() => {
    return () => {
      swrInfiniteResponse.setSize(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** @type {import("react").MutableRefObject<HTMLElement[]>} */
  const optionsRefs = useRef([]);

  const form = useForm({
    defaultValues: {
      term: "",
    },
  });

  const items = useMemo(() => {
    return (
      swrInfiniteResponse.data?.reduce?.(
        (acc, page) => [...acc, ...page.data],
        [],
      ) ?? []
    );
  }, [swrInfiniteResponse.data]);

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      const persistentValue = !persistentValues.includes(
        resolveItemValue({ item }),
      );

      if (filterItemValue && persistentValue) {
        return filterItemValue({ item });
      }
      return persistentValue;
    });
  }, [items, persistentValues, resolveItemValue, filterItemValue]);

  const handleScroll = useCallback(
    (event) => {
      const target = event.target;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight + 100) {
        // we add extra offset for preloading
        if (!isFullyLoaded && !swrInfiniteResponse.isValidating) {
          swrInfiniteResponse.setSize((size) => size + 1);
        }
      }
    },
    [isFullyLoaded, swrInfiniteResponse],
  );

  const handleAutosubmit = useCallback(() => {
    setSubmittedFields(form.getValues());
    setPersistentValues(values);
  }, [form, setPersistentValues, values]);

  const addValue = useCallback(
    /**
     * @param {object} param0
     * @param {import(".").AutocompleteItemValue} param0.value
     * @param {object} param0.item
     */
    ({ value, item }) => {
      onChange([...values, value]);
      setValueItemMap((prev) => {
        return {
          ...prev,
          [value]: item,
        };
      });
      if (!isMultiple) {
        onClose();
      }
    },
    [isMultiple, onChange, onClose, setValueItemMap, values],
  );

  const toggleValue = useCallback(
    /** @type {import(".").AutoCompleteToggleValue} */
    function toggleValue({ value, item: _item }) {
      const item = _item ?? resolveItemByValue({ value });
      if (values.includes(value)) {
        removeValue({ value });
      } else {
        addValue({ value, item });
      }
    },
    [addValue, removeValue, resolveItemByValue, values],
  );

  // automatically fetch more results if there is no scrollbar
  useLayoutEffect(() => {
    const popoverBody = refPopoverBody.current;
    if (!popoverBody) {
      return;
    }
    if (
      !swrInfiniteResponse.isValidating &&
      !swrInfiniteResponse.error &&
      !isFullyLoaded
    ) {
      // if the popover is scrolled all the way down
      const isAtBottom = popoverBody.clientHeight === popoverBody.scrollHeight;
      if (isAtBottom) {
        swrInfiniteResponse.setSize((size) => size + 1);
      }
    }
  });

  const toggleFirstOption = useCallback(() => {
    if (items.length > 0) {
      toggleValue({
        value: resolveItemValue({ item: items[0] }),
        item: items[0],
      });
    }
  }, [items, resolveItemValue, toggleValue]);

  return (
    <PopoverBody
      ref={refPopoverBody}
      p="0"
      h="100%"
      overflowY="auto"
      onScroll={handleScroll}>
      <Box>
        <FormProvider {...form}>
          <FormAutoSubmit onSubmit={handleAutosubmit} />

          <Box
            position="sticky"
            top="0"
            backgroundColor={backgroundColor}
            zIndex={1}>
            <SearchInput
              inputRef={inputRef}
              optionsRef={optionsRefs}
              toggleFirstOption={toggleFirstOption}
              isValidating={swrInfiniteResponse.isValidating}
              submittedFields={submittedFields}
            />
          </Box>

          <ObserverRHF
            names={["term"]}
            render={({ values: [term] }) => (
              <>
                {term.length >= minSearchTermLength && (
                  <Box py=".5rem">
                    {persistentValues.length > 0 && (
                      <>
                        {persistentValues.map((itemValue, index) => {
                          const item = resolveItemByValue({ value: itemValue });
                          return (
                            <Fragment key={itemValue}>
                              {renderOption({
                                ref: (el) => {
                                  optionsRefs.current[index] = el;
                                },
                                value: _value,
                                item,
                                itemValue,
                                name: item
                                  ? resolveItemName({ item })
                                  : undefined,
                                toggleValue,
                                focusPrev: () => {
                                  if (index === 0) {
                                    inputRef.current?.focus?.();
                                    inputRef.current?.select?.();
                                  }
                                  optionsRefs.current?.[index - 1]?.focus?.();
                                },
                                focusNext: () => {
                                  optionsRefs.current?.[index + 1]?.focus?.();
                                },
                              })}
                            </Fragment>
                          );
                        })}
                      </>
                    )}

                    {persistentValues.length > 0 &&
                      (filteredItems.length > 0 ||
                        (!swrInfiniteResponse.isLoading &&
                          items.length === 0)) && (
                        <Box my=".5rem">
                          <Divider />
                        </Box>
                      )}

                    {filteredItems.map?.((item, _index) => {
                      const index = _index + persistentValues.length;
                      return (
                        <Fragment key={resolveItemValue({ item })}>
                          {renderOption({
                            ref: (el) => {
                              optionsRefs.current[index] = el;
                            },
                            value: _value,
                            item,
                            itemValue: resolveItemValue({ item }),
                            name: resolveItemName({ item }),
                            toggleValue,
                            focusPrev: () => {
                              if (index === 0) {
                                inputRef.current?.focus?.();
                                inputRef.current?.select?.();
                              }
                              optionsRefs.current?.[index - 1]?.focus?.();
                            },
                            focusNext: () => {
                              optionsRefs.current?.[index + 1]?.focus?.();
                            },
                          })}
                        </Fragment>
                      );
                    })}

                    {!swrInfiniteResponse.isLoading && items.length === 0 && (
                      <Text fontStyle="italic" px="1rem">
                        <FormattedMessage defaultMessage="Aucun résultat" />
                      </Text>
                    )}

                    <Progress
                      position="absolute"
                      bottom="0"
                      left="0"
                      right="0"
                      size="xs"
                      isIndeterminate
                      transition="opacity .25s ease"
                      opacity={swrInfiniteResponse.isValidating ? 1 : 0}
                    />
                  </Box>
                )}
              </>
            )}
          />
        </FormProvider>
      </Box>
    </PopoverBody>
  );
}

export default AutocompleteBody;

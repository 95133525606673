import { useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  SEARCH_DATES_MODES_VALUE_FLEXIBLE,
  SEARCH_DATES_MODES_VALUE_PRECISE,
  SEARCH_DURATIONS,
} from "../../../constants/Search";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("../../../containers/Search/Search/Handler").SearchFormValues>} form
 * @property {string} fallback
 */
/**
 * @param {Props} props
 */
export function DatesRecap({ form, fallback }) {
  const intl = useIntl();

  const checkin = useWatch({ control: form.control, name: "checkin" });
  const checkout = useWatch({ control: form.control, name: "checkout" });
  const datesMode = useWatch({ control: form.control, name: "dates_mode" });
  const month = useWatch({ control: form.control, name: "month" });
  const duration = useWatch({ control: form.control, name: "duration" });

  return (
    <>
      {datesMode === SEARCH_DATES_MODES_VALUE_PRECISE &&
      checkin !== "" &&
      checkout !== ""
        ? intl.formatMessage(
            {
              defaultMessage: "{checkin} - {checkout}",
            },
            {
              checkin: intl.formatDate(checkin, {
                month: "short",
                day: "numeric",
                timeZone: "UTC",
              }),
              checkout: intl.formatDate(checkout, {
                month: "short",
                day: "numeric",
                timeZone: "UTC",
              }),
            },
          )
        : datesMode === SEARCH_DATES_MODES_VALUE_FLEXIBLE && month !== ""
        ? intl.formatMessage(
            { defaultMessage: "{duration} - {month}" },
            {
              duration: intl.formatMessage(SEARCH_DURATIONS[duration].label),
              month: intl.formatDate(month, {
                month: "short",
                year: "numeric",
                timeZone: "UTC",
              }),
            },
          )
        : datesMode === SEARCH_DATES_MODES_VALUE_FLEXIBLE && month === ""
        ? intl.formatMessage(
            { defaultMessage: "{duration}" },
            {
              duration: intl.formatMessage(SEARCH_DURATIONS[duration].label),
            },
          )
        : fallback}
    </>
  );
}

import { defineMessage } from "react-intl";

/** @type {import("../types/Region").RegionFront[]} */
export const LEGACY_REGIONS = [
  {
    name: defineMessage({ defaultMessage: "Alsace" }),
    code: "ALS",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Aquitaine" }),
    code: "AQU",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Auvergne" }),
    code: "AUV",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Bourgogne" }),
    code: "BOU",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Bretagne" }),
    code: "BRE",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Centre-Val de Loire-Berry" }),
    code: "CEN",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Champagne-Ardenne" }),
    code: "CHA",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Corse" }),
    code: "COR",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Franche Comte" }),
    code: "FRC",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Ile de France" }),
    code: "IDF",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Languedoc-Roussillon" }),
    code: "LAR",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Limousin" }),
    code: "LIM",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Lorraine" }),
    code: "LOR",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Midi-Pyrénées" }),
    code: "MIP",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Normandie" }),
    code: "NOR",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Nord Pas de Calais" }),
    code: "NPC",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Provence-Alpes-Cote-d'Azur" }),
    code: "PAC",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Poitou-Charentes" }),
    code: "PCH",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Pays de la Loire" }),
    code: "PDL",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Picardie" }),
    code: "PIC",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Rhône-Alpes" }),
    code: "RHA",
    svg: null,
  },
  {
    name: defineMessage({ defaultMessage: "Dom-Tom" }),
    code: "DTO",
    svg: null,
  },
];

import Error404 from "../containers/404";
import Error404Public from "../containers/404Public";
import Error410 from "../containers/410";
import Error410Public from "../containers/410Public";
import { Error500 } from "../containers/500";
import { Error500Public } from "../containers/500Public";
import BlankLayout from "../containers/Layouts/BlankLayout";
import { BasicLayout } from "../containers/Layouts/BasicLayout";
import MaintenanceLayout from "../containers/Layouts/MaintenanceLayout";
import { PublicLayout } from "../containers/Layouts/PublicLayout";
import { PublicLargeLayout } from "../containers/Layouts/PublicLargeLayout";
import { SignedInLayout } from "../containers/Layouts/SignedInLayout";
import { SignedOutLayout } from "../containers/Layouts/SignedOutLayout";
import { THEME_ACCOUNT } from "./themes/account";
import { THEME_PUBLIC } from "./themes/public";

/**
 * @template {string | number | symbol} T
 * @typedef {Record.<T, import("../types/Layouts").LayoutDeclaration<T>>} LayoutsRecord
 */

/**
 * @type {LayoutsRecord<import("../types/Layouts").LayoutName>}
 */
export const LAYOUTS = {
  maintenance: {
    name: "maintenance",
    component: MaintenanceLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404Public,
    error500Component: Error500Public,
    error410Component: Error410Public,
  },
  signedOut: {
    name: "signedOut",
    component: SignedOutLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404,
    error500Component: Error500,
    error410Component: Error410,
  },
  signedIn: {
    name: "signedIn",
    component: SignedInLayout,
    theme: THEME_ACCOUNT,
    error404Component: Error404,
    error500Component: Error500,
    error410Component: Error410,
  },
  blank: {
    name: "blank",
    component: BlankLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404,
    error500Component: Error500,
    error410Component: Error410,
  },
  basic: {
    name: "basic",
    component: BasicLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404,
    error500Component: Error500,
    error410Component: Error410,
  },
  public: {
    name: "public",
    component: PublicLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404Public,
    error500Component: Error500Public,
    error410Component: Error410Public,
  },
  publicLarge: {
    name: "publicLarge",
    component: PublicLargeLayout,
    theme: THEME_PUBLIC,
    error404Component: Error404Public,
    error500Component: Error500Public,
    error410Component: Error410Public,
  },
};

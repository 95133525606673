import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  HStack,
  LightMode,
  Text,
} from "@chakra-ui/react";
import { useApiError } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { DEFAULT_ENVIRONMENT_ID } from "@raiden/library-ui/constants/environments";
import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

/**
 * @typedef {object} NewsgroupSubscriberFrontFormValues
 * @property {object} data
 * @property {number | string} data.environment_id
 * @property {string} data.email
 * @property {string} data.locale
 * @property {object} meta
 * @property {boolean} meta.notify
 * @property {boolean} meta.accept_conditions
 */
/**
 * @param {string} locale
 * @returns {NewsgroupSubscriberFrontFormValues}
 */
function getDefaultValues(locale) {
  return {
    data: {
      environment_id: DEFAULT_ENVIRONMENT_ID,
      email: "",
      locale,
    },
    meta: {
      notify: true,
      accept_conditions: false,
    },
  };
}

/**
 * @typedef {object} Props
 * @property {() => void} onSuccess
 */
/**
 * @param {Props} props
 */
export function Handler(props) {
  const { onSuccess } = props;

  const intl = useIntl();

  const { locale } = useLocale();

  /** @type {import("react-hook-form").UseFormReturn<NewsgroupSubscriberFrontFormValues>} */
  const form = useForm({
    defaultValues: getDefaultValues(locale),
  });
  const { handleSubmit } = form;

  const { request, isLoading, toastSuccess, toastError } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit(async (fields) => {
        if (!executeRecaptcha) {
          console.warn("Execute recaptcha not yet available");
          toastError({
            id: "invalid-recaptcha",
            status: "error",
            description: intl.formatMessage({
              defaultMessage: "Impossible de charger le module de sécurité",
            }),
            title: intl.formatMessage({
              defaultMessage: "Erreur",
            }),
            isClosable: true,
          });
          return;
        }

        const token = await executeRecaptcha("submit_form_contact");
        request(
          generateApiUrl({
            id: "@api.newsgroups.subscribers.create",
          }),
          {
            method: "POST",
            body: {
              data: fields.data,
              meta: {
                ...fields.meta,
                recaptcha_token: token,
              },
            },
          },
        )
          .then(() => {
            toastSuccess({
              title: intl.formatMessage({
                defaultMessage: "Vous allez recevoir un e-mail de confirmation",
              }),
            });
            resetApiErrors();
            onSuccess();
            form.reset(getDefaultValues(locale));
          })
          .catch((response) => {
            toastSuccess({
              title: response?.body?.errors?.[0]?.detail
                ? apiGetErrorDetail({ error: response })
                : intl.formatMessage({
                    defaultMessage:
                      "Une erreur est survenue lors de l'inscription.",
                  }),
              status: "error",
            });
            resolveApiError({ response, form });
          });
      })();
    },
    [
      executeRecaptcha,
      form,
      handleSubmit,
      intl,
      locale,
      onSuccess,
      request,
      resetApiErrors,
      resolveApiError,
      toastError,
      toastSuccess,
    ],
  );

  return (
    <FormProvider {...form}>
      <Box mt=".8rem">
        <form
          id="createNewsgroupsSubscribers"
          onSubmit={submit}
          autoComplete="off"
          noValidate>
          <Box>
            <HStack mr="2rem" spacing="1rem" alignItems="start">
              <FormControlRHF
                w="full"
                type="email"
                name="data.email"
                rules={{ required: true }}
                placeholder={intl.formatMessage({
                  defaultMessage: "Votre adresse e-mail",
                })}
                inputProps={{
                  bgColor: "white",
                }}
              />

              <LightMode>
                <Button
                  form="createNewsgroupsSubscribers"
                  type="submit"
                  isLoading={isLoading}
                  colorScheme="brandSecondary">
                  {intl.formatMessage({
                    defaultMessage: "M'abonner",
                  })}
                </Button>
              </LightMode>
            </HStack>

            <FormControlRHF
              name="meta.accept_conditions"
              rules={{ required: true }}
              renderWithFormControl={(field) => (
                <Checkbox mt="1rem" isChecked={field.value} {...field}>
                  <FormLabel as={Text} fontSize=".875rem" m="0">
                    {intl.formatMessage({
                      defaultMessage:
                        "En acceptant ce formulaire, j'accepte que mes données personnelles soient utilisées pour me recontacter dans le cadre de ma demande indiquée dans ce formulaire.",
                    })}
                  </FormLabel>
                </Checkbox>
              )}
            />
          </Box>
        </form>
      </Box>
    </FormProvider>
  );
}

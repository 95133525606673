import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";
import { initNumber } from "../../../helpers/form/initNumber";
import { Icon } from "../../Icon";

export const GuestInput = forwardRef(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactElement} title
   * @property {string} [description]
   * @property {string} icon
   * @property {number} [min]
   * @property {number} [max]
   * @property {import("react").ReactElement} [helperText]
   */
  /**
   * @param {import("../../../components/ReactHookForm/FormControlRHF").FieldProps<number> & Props} props
   */
  function GuestInput(
    {
      title,
      description,
      icon,
      name,
      value: _value,
      onChange: _onChange,
      isDisabled,
      min = 0,
      max = Infinity,
      helperText,
    },
    ref,
  ) {
    const value = initNumber(_value);

    const onChange = useCallback(
      /**
       * @param {number} value
       */
      (value) => {
        _onChange({ target: { name, value } });
      },
      [_onChange, name],
    );

    const isMinusDisabled = value <= min;
    const isPlusDisabled = value >= max;

    return (
      <Stack spacing=".125rem">
        <HStack>
          <Box>
            <Icon icon={icon} />
          </Box>

          <Stack flexGrow={1} spacing="0">
            {title}

            <Text variant="text-xs" color="gray.500">
              {description}
            </Text>
          </Stack>

          <HStack spacing=".5rem" pl="1.5rem">
            <Button
              ref={
                isMinusDisabled ? undefined : isPlusDisabled ? ref : undefined
              }
              onClick={() => {
                onChange(Math.max(value - 1, 0));
              }}
              isDisabled={isMinusDisabled || isDisabled}
              variant="outline"
              borderRadius="full">
              -
            </Button>

            <Text
              fontSize="1rem"
              fontWeight={400}
              lineHeight="1.3125rem"
              textAlign="center"
              minW="1.5rem"
              color={value > 0 ? undefined : "gray.500"}>
              {value}
            </Text>

            <Button
              ref={isPlusDisabled ? undefined : ref}
              onClick={() => {
                onChange(value + 1);
              }}
              isDisabled={isPlusDisabled || isDisabled}
              variant="outline"
              borderRadius="full">
              +
            </Button>
          </HStack>
        </HStack>
        {helperText}
      </Stack>
    );
  },
);

// polyfills
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

// imports
import { ChakraProvider } from "@chakra-ui/react";
import { DataHandlerProvider } from "@raiden/library-ui/components/DataHandler";
import { DataHandlerInfiniteProvider } from "@raiden/library-ui/components/DataHandlerInfinite";
import { ErrorBoundaryDegraded } from "@raiden/library-ui/components/ErrorBoundaryDegraded";
import { IconProvider } from "@raiden/library-ui/components/Icon";
import { COOKIES_NAME_LIST } from "@raiden/library-ui/constants/cookies";
import { frontBaseUri } from "@raiden/library-ui/constants/routers/front";
import AuthProvider from "@raiden/library-ui/contexts/Auth";
import { ConfigurationProvider } from "@raiden/library-ui/contexts/Configuration";
import { MaintenanceModeProvider } from "@raiden/library-ui/contexts/MaintenanceMode";
import { PreferencesProvider } from "@raiden/library-ui/contexts/Preferences";
import { SplashContext } from "@raiden/library-ui/contexts/Splash";
import { TranslationMessagesProvider } from "@raiden/library-ui/contexts/TranslationMessages";
import { TwoFaProvider } from "@raiden/library-ui/contexts/TwoFaContext";
import { apiGetErrorStatus } from "@raiden/library-ui/helpers/api";
import {
  nextCheckMaintenance,
  nextGetConfiguration,
  nextGetCookies,
  nextGetRedirectionsUrls,
  nextGetUser,
  nextSetUser,
  nextIsAuthorized,
} from "@raiden/library-ui/helpers/next";
import generateAdminUrl from "@raiden/library-ui/libraries/utils/generateAdminUrl";
import { I18nProvider } from "@react-aria/i18n";
import { SSRProvider } from "@react-aria/ssr";
import "focus-visible/dist/focus-visible";
import { useMemo } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { MaintenanceModal } from "../components/MaintenanceModal";
import ProgressBar from "../components/ProgressBar";
import DefaultSeo from "../components/Seo/DefaultSeo";
import { AUTH_FIELDS } from "../constants/auth";
import { DATA_HANDLER_CONTEXT_VALUE } from "../constants/dataHandler";
import { DATA_HANDLER_INFINITIE_CONTEXT_VALUE } from "../constants/dataHandlerInfinite";
import { LAYOUTS } from "../constants/layouts";
import { getConfigurationUrl } from "../helpers/configuration/getConfigurationUrl";
import { nextGetFrontLayout } from "../helpers/next/getFrontLayout";
import { useAutoLayout } from "../hooks/useAutoLayout";
import { CookiesProvider } from "../hooks/useCookies";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";
import { LanguagePickerProvider } from "@raiden/library-ui/components/Form/LanguagePicker";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";

const SWR_CONFIG = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

/**
 * @param {string} [image="default"]
 * @returns {string}
 */
function setSrc(image = "default") {
  return generateAdminUrl({
    id: "internal-assets",
    parameters: {
      filePath: `splashes/${image}.png`,
    },
    includeBasePath: true,
  });
}

/**
 * @param {string} img
 * @returns {string}
 */
function generateSrc(img) {
  return generateFrontPath({
    id: "@front.internal-assets",
    parameters: {
      filePath: img,
    },
    includeBasePath: true,
  });
}

/**
 * @typedef {object} Props
 * @property {import("../types/Page").PageInitialProps} [initialProps]
 * @property {Record<string, any>} cookies
 * @property {string} [locale]
 * @property {object} initialTranslationMessages
 * @property {import("@raiden/library-ui/types/Configuration").Configuration} [configuration]
 * @property {import("@raiden/library-ui/helpers/next/isAuthorized").NextIsAuthorizedReturn} authorized
 * @property {import("@raiden/library-ui/types/User").UserBase} [user]
 * @property {import("../types/Layouts").LayoutName} [layoutName]
 * @property {import("@raiden/library-ui/helpers/next/getUser").NextGetUserReturn} [userData]
 * @property {boolean} isMaintenance
 */
/**
 * @param {Props & { Component: import("react").FC<import("../types/Page").PageInitialProps>}} props
 */
export default function App({
  Component,
  authorized,
  initialProps,
  cookies,
  locale,
  initialTranslationMessages,
  configuration,
  user,
  layoutName,
  isMaintenance,
  userData,
}) {
  const autoLayout = useAutoLayout({ user }); // used to display error pages if the layout is not found

  nextSetUser(userData);

  const layout = isMaintenance
    ? LAYOUTS.maintenance
    : layoutName
    ? LAYOUTS[layoutName] ?? autoLayout
    : autoLayout;

  /** @type {import("@raiden/library-ui/contexts/Splash").SplashContextValue} **/
  const SPLASH_CONTEXT_DEFAULT_VALUE = useMemo(() => {
    return {
      setSrc,
    };
  }, []);

  /** @type {import("@raiden/library-ui/components/Form/LanguagePicker").LanguagePickerContextValue} **/
  const LANGUAGE_PICKER__CONTEXT_DEFAULT_VALUE = useMemo(() => {
    return { generateSrc };
  }, []);

  return (
    <ErrorBoundaryDegraded>
      <CookiesProvider initialCookies={cookies}>
        <SWRConfig value={SWR_CONFIG}>
          <TranslationMessagesProvider
            initialTranslationMessages={initialTranslationMessages}>
            {({ translationMessages }) => (
              <>
                {locale && (
                  <IntlProvider
                    locale={locale}
                    defaultLocale={process.env.NEXT_PUBLIC_DEFAULT_LOCALE}
                    messages={translationMessages}>
                    <I18nProvider locale={locale}>
                      <SSRProvider>
                        <ChakraProvider theme={layout.theme}>
                          <IconProvider defaultSize="1rem">
                            <LanguagePickerProvider
                              generateSrc={
                                LANGUAGE_PICKER__CONTEXT_DEFAULT_VALUE
                              }>
                              <DataHandlerProvider
                                value={DATA_HANDLER_CONTEXT_VALUE}>
                                <DataHandlerInfiniteProvider
                                  value={DATA_HANDLER_INFINITIE_CONTEXT_VALUE}>
                                  <SplashContext.Provider
                                    value={SPLASH_CONTEXT_DEFAULT_VALUE}>
                                    <MaintenanceModeProvider>
                                      <AuthProvider
                                        initialUser={user}
                                        fields={AUTH_FIELDS}>
                                        <ConfigurationProvider
                                          initialConfiguration={configuration}
                                          configurationUrl={getConfigurationUrl(
                                            {
                                              isLoggedIn: Boolean(user),
                                            },
                                          )}>
                                          <ErrorBoundary>
                                            <TwoFaProvider>
                                              <PreferencesProvider>
                                                <DefaultSeo />

                                                <layout.component>
                                                  {authorized.globally ? (
                                                    <Component
                                                      {...initialProps}
                                                    />
                                                  ) : (
                                                    <layout.error404Component />
                                                  )}
                                                </layout.component>

                                                <ProgressBar />

                                                {layout.name !==
                                                  "maintenance" && (
                                                  <MaintenanceModal />
                                                )}
                                              </PreferencesProvider>
                                            </TwoFaProvider>
                                          </ErrorBoundary>
                                        </ConfigurationProvider>
                                      </AuthProvider>
                                    </MaintenanceModeProvider>
                                  </SplashContext.Provider>
                                </DataHandlerInfiniteProvider>
                              </DataHandlerProvider>
                            </LanguagePickerProvider>
                          </IconProvider>
                        </ChakraProvider>
                      </SSRProvider>
                    </I18nProvider>
                  </IntlProvider>
                )}
              </>
            )}
          </TranslationMessagesProvider>
        </SWRConfig>
      </CookiesProvider>
    </ErrorBoundaryDegraded>
  );
}

/**
 * @param {object} appProps
 * @param {import("next").NextComponentType<import("../types/AppContext").PageContext, import("../types/Page").PageInitialProps>} appProps.Component
 * @param {import("next/dist/shared/lib/utils").AppTreeType} appProps.AppTree
 * @param {import("next").NextPageContext} appProps.ctx
 * @param {import("next/router").default} appProps.router
 */
App.getInitialProps = async function (appProps) {
  const {
    Component,
    router: { locale },
  } = appProps;

  await nextCheckMaintenance({ res: appProps.ctx.res });

  const defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE;

  const { cookies } = await nextGetCookies({
    req: appProps.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;
      return cookies;
    }, {}),
  });

  // only fetch translation messages on the server on initial render
  const initialTranslationMessages =
    typeof window === "undefined" &&
    locale !== undefined &&
    defaultLocale !== undefined
      ? await getTranslationMessages(locale, defaultLocale)
      : null;
  /** @type {import("@raiden/library-ui/helpers/next/getUser").NextGetUserReturn | undefined} */
  const userData =
    process.env.NEXT_PUBLIC_USE_AUTH === "true"
      ? await nextGetUser({
          cookies,
          baseUri: frontBaseUri,
          locale,
          req: appProps.ctx.req,
          fields: AUTH_FIELDS,
        })
      : undefined;
  const user = userData?.user;

  // only fetch configuration on the server on initial render
  const configurationData =
    typeof window === "undefined"
      ? await nextGetConfiguration({
          cookies,
          baseUri: frontBaseUri,
          locale,
          req: appProps.ctx.req,
          configUrl: getConfigurationUrl({ isLoggedIn: Boolean(user) }),
        })
      : null;

  const configuration = configurationData?.configuration;

  const [initialProps] = await Promise.all([
    Component.getInitialProps?.({
      ...appProps.ctx,
      configuration: configuration,
      cookies,
    }),
    intlPolyfills(locale),
  ]);

  const layoutName = await nextGetFrontLayout({
    nextPageContext: appProps.ctx,
    pageLayout: initialProps?.layout,
    tooManyRequest: configurationData?.tooManyRequest,
    user,
  });

  await nextGetRedirectionsUrls({
    nextPageContext: appProps.ctx,
    baseUri: frontBaseUri,
    locale,
  });

  const authorized = await nextIsAuthorized({
    guard: initialProps?.guards,
    user: user,
  });

  const isMaintenance =
    503 === apiGetErrorStatus({ error: userData?.error }) ||
    503 === apiGetErrorStatus({ error: configurationData?.error });

  /** @type {Props} */
  const values = {
    authorized,
    initialProps,
    locale,
    cookies,
    initialTranslationMessages,
    configuration,
    user,
    userData,
    layoutName,
    isMaintenance,
  };

  return values;
};

import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";

/**
 * @param {object} params
 * @param {boolean} params.isLoggedIn
 */
export function getConfigurationUrl({ isLoggedIn }) {
  return generateApiUrl({
    id: "@configuration",
    query: {
      fields: [
        "covers",
        "menus",
        "posts",
        "posts_categories",
        "auth",
        "amenities_categories",
        "hotels_types",
        "ota_types",
        "regions",
        "departments",
        "customers_contacts_categories",
        "newsgroups",
        "searchable_amenities",
        ...(isLoggedIn ? ["users_notifications_preferences", "years"] : []),
      ],
    },
  });
}

import { Image } from "@chakra-ui/react";
import { memo } from "react";
import generateAdminPath from "../../libraries/utils/generateAdminPath";
import generatePampaUrl from "../../libraries/utils/generatePampaUri";
import ImageLoaderHandler from "../ImageLoaderHandler";

const IMAGE_WIDTHS = (process.env.NEXT_PUBLIC_DEVICE_SIZES ?? "")
  .split(/, ?/)
  .map((deviceSize) => parseInt(deviceSize));

const DEFAULT_QUALITY = parseInt(
  process.env.NEXT_PUBLIC_PAMPA_DEFAULT_QUALITY ?? "",
);
/**
 * @param {object} param0
 * @param {import("../../types/Cover").Coverizable} param0.image
 * @param {"resize" | "crop"} param0.mode
 * @param {string} [param0.format]
 * @param {number} [param0.quality]
 * @param {string} [param0.outputFormat]
 * @param {number} [param0.maxContainerWidth]
 */
function resolveSrcSet({
  image,
  mode,
  format,
  quality,
  outputFormat,
  maxContainerWidth = IMAGE_WIDTHS[IMAGE_WIDTHS.length - 1],
}) {
  const blocks = [];

  // ajout du maxContainerWidth s'il n'est pas présent dans le tableau des widths
  const initialWidths = [...IMAGE_WIDTHS];
  if (!initialWidths.includes(maxContainerWidth)) {
    initialWidths.push(maxContainerWidth);
    initialWidths.sort((a, b) => a - b);
  }

  // détermine l'ensemble des widths pertinentes pour l'image
  // ne prends pas en compte les widths qui sont supérieurs à maxContainerWidth
  const widths = [];
  for (let i = 0; i < initialWidths.length; i++) {
    const width = initialWidths[i];
    if (width <= maxContainerWidth) {
      widths.push(width);
    } else {
      break;
    }
  }

  widths.forEach((width) => {
    blocks.push(
      `${generatePampaUrl({
        image,
        mode,
        format,
        width,
        outputFormat,
        quality: quality ?? DEFAULT_QUALITY,
      })} ${width}w`,
    );
  });
  return blocks.join(", ");
}

/**
 * @typedef {object} Props
 * @property {import("../../types/Cover").Coverizable | null} [image]
 * @property {"resize" | "crop"} mode
 * @property {string} alt
 * @property {string} [format]
 * @property {number} [quality]
 * @property {string} [outputFormat]
 * @property {number} [maxContainerWidth]
 * @property {string} [placeholderSrc]
 * @property {import("@chakra-ui/react").ImageProps["loading"]} [loading]
 */
/**
 * @param {Omit<import("@chakra-ui/react").ImageProps, "src"> & Props} props
 */
function PampaImage({
  image,
  mode,
  alt,
  format,
  quality,
  outputFormat,
  maxContainerWidth,
  placeholderSrc,
  loading = "lazy",
  ...otherProps
}) {
  return (
    <picture>
      {image && (
        <source
          srcSet={resolveSrcSet({
            image,
            mode,
            format,
            maxContainerWidth,
            quality,
            outputFormat,
          })}
        />
      )}

      <ImageLoaderHandler
        src={
          image
            ? generatePampaUrl({
                image,
                mode,
                format,
                width: maxContainerWidth,
                outputFormat,
                quality,
              })
            : placeholderSrc ??
              generateAdminPath({
                id: "internal-assets",
                parameters: { filePath: "/placeholder/placeholder.svg" },
                includeBasePath: true,
              })
        }>
        {({ ref, src, isLoaded, handleLoad }) => (
          <Image
            {...otherProps}
            ref={ref}
            src={src}
            loading={loading}
            alt={alt}
            opacity={isLoaded ? 1 : 0}
            transition="opacity 0.25s ease"
            onLoad={handleLoad}
          />
        )}
      </ImageLoaderHandler>
    </picture>
  );
}

export default memo(PampaImage);

import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} SelectDrawerOption
 * @property {string} name
 * @property {any} value
 */

export const SelectDrawer = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} name
   * @property {any} value
   * @property {(params: {target: {name: string, value: any }}) => void} onChange
   * @property {SelectDrawerOption[]} options
   * @property {string} title
   * @property {import("@chakra-ui/react").PlacementWithLogical} [popoverPlacement]
   * @property {boolean} [allowUnselect]
   * @property {boolean} [isDisabled]
   * @property {import("react").ReactElement} children
   */
  /**
   * @param {Props} props
   */
  function SelectDrawer(
    {
      value,
      onChange,
      options,
      title,
      popoverPlacement,
      allowUnselect = true,
      isDisabled = false,
      children,
    },
    ref,
  ) {
    const intl = useIntl();

    const { isOpen, onOpen: _onOpen, onClose } = useDisclosure();

    const isMobile = useBreakpointValue({ base: true, lg: false }) ?? true;

    const handleClickOption = useCallback(
      ({ option }) => {
        if (value === option.value && allowUnselect) {
          onChange({ target: { name: "sort", value: "" } });
          onClose();
        } else {
          onChange({ target: { name: "sort", value: option.value } });
          onClose();
        }
      },
      [allowUnselect, onChange, onClose, value],
    );

    const optionsElement = (
      <Stack spacing=".5rem">
        {options.map((option) => (
          <HStack
            as="a"
            cursor="pointer"
            onClick={() => handleClickOption({ option })}
            key={option.value}>
            {value === option.value && <Icon icon="ms_check" />}

            <Text>
              {option.name.charAt(0).toLocaleUpperCase() + option.name.slice(1)}
            </Text>
          </HStack>
        ))}
      </Stack>
    );

    const onOpen = useCallback(() => {
      if (!isDisabled) {
        _onOpen();
      }
    }, [_onOpen, isDisabled]);

    return (
      <>
        <Popover
          isOpen={isOpen && !isMobile}
          onClose={onClose}
          placement={popoverPlacement}
          computePositionOnMount={true}
          isLazy={true}>
          <PopoverTrigger>
            <Box ref={ref} as="a" display="inline-block" onClick={onOpen}>
              {children}
            </Box>
          </PopoverTrigger>

          <PopoverContent>
            <PopoverArrow />

            <PopoverBody>{optionsElement}</PopoverBody>
          </PopoverContent>
        </Popover>

        <Drawer
          isOpen={isOpen && isMobile}
          onClose={onClose}
          placement="bottom">
          <DrawerOverlay />

          <DrawerContent>
            <DrawerBody px="1rem" py="1.25rem">
              <Stack spacing="1rem">
                <HStack>
                  <Text variant="h4" flexGrow={1}>
                    {title}
                  </Text>

                  <IconButton
                    icon={<Icon icon="ms_close" size="1.25rem" />}
                    variant="ghost"
                    onClick={onClose}
                    aria-label={intl.formatMessage({
                      defaultMessage: "Fermer",
                    })}
                  />
                </HStack>

                <Divider />

                <Box>{optionsElement}</Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    );
  },
);

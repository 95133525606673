import {
  AspectRatio,
  Box,
  Flex,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { memo } from "react";
import { BreadcrumbsPublic } from "../BreadcrumbsPublic";
import { CenteredContent } from "../CenteredContent";
import { Href } from "../Href";

export const PageHeaderPublic = memo(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactNode} title
   * @property {import("react").ReactNode} [subtitle]
   * @property {string} [description]
   * @property {number} [descriptionNoOfLines]
   * @property {import("../BreadcrumbsPublic").BreadCrumbItemPublic[]} [breadCrumbItems]
   * @property {import("react").ReactNode} [image] set to null when fetching the data
   * @property {import("react").ReactNode} [addon]
   * @property {import("react").ReactNode} [addonBottom]
   * @property {import("react").ReactNode} [isLoading]
   * @property {string} [href]
   * @property {import("react").ReactNode} [children]
   */
  /**
   * @param {Props} props
   */
  function PageHeaderPublic({
    title,
    subtitle,
    description,
    descriptionNoOfLines,
    breadCrumbItems,
    image,
    addon,
    addonBottom,
    isLoading = false,
    href,
    children,
  }) {
    return (
      <Box mt="1.5rem">
        <Box px={{ lg: "1rem" }}>
          <CenteredContent>
            <Skeleton isLoaded={!isLoading} borderRadius={{ lg: "10px" }}>
              <Box
                h={
                  image !== undefined
                    ? { lg: "470px", "2xl": "405px" }
                    : undefined
                }
                position="relative">
                <Flex flexDirection="column" h="full" position="relative">
                  {/* image to be display on desktop */}

                  {image !== undefined && (
                    <Box
                      display={{ base: "none", lg: "block" }}
                      sx={{
                        maskImage:
                          "linear-gradient(115deg, transparent 20% , black 50%)",
                      }}
                      position={{ base: "initial", lg: "absolute" }}
                      pointerEvents="none"
                      top="0"
                      right="0"
                      height="100%"
                      borderRadius={{ lg: "10px" }}
                      overflow="hidden"
                      backgroundColor="#ccc">
                      {image}
                    </Box>
                  )}

                  <Stack flexGrow={1} spacing="1rem" zIndex={1}>
                    <Stack
                      spacing="1rem"
                      px={{ base: "1rem", lg: 0 }}
                      flexGrow={1}>
                      <BreadcrumbsPublic items={breadCrumbItems} />

                      <Href href={href}>
                        <Stack spacing=".75rem">
                          <Text
                            as="h1"
                            variant="h2"
                            maxW={
                              image
                                ? { base: "full", lg: "35%", "2xl": "42%" }
                                : undefined
                            }>
                            {title}
                          </Text>

                          {subtitle && (
                            <Text
                              as="h2"
                              variant="h3"
                              maxW={
                                image
                                  ? { base: "full", lg: "35%", "2xl": "42%" }
                                  : undefined
                              }>
                              {subtitle}
                            </Text>
                          )}

                          {description && (
                            <Box
                              maxW={
                                image
                                  ? { base: "full", lg: "35%", "2xl": "42%" }
                                  : undefined
                              }>
                              <Text
                                flexGrow={1}
                                variant="text-md"
                                noOfLines={{ lg: descriptionNoOfLines }}>
                                {description.replace(
                                  /\[http:\/\/www[^\]]*\]/g,
                                  "",
                                )}
                              </Text>
                            </Box>
                          )}
                        </Stack>
                      </Href>

                      {addon && <Box flexShrink={0}>{addon}</Box>}
                    </Stack>
                  </Stack>

                  {/* image to be display on mobile */}

                  {image !== undefined && (
                    <Box display={{ base: "block", lg: "none" }} mt="1rem">
                      <AspectRatio ratio={904 / 405}>
                        {image ?? <Box />}
                      </AspectRatio>
                    </Box>
                  )}

                  {addonBottom && addonBottom}
                </Flex>
              </Box>
            </Skeleton>
          </CenteredContent>
        </Box>

        {children}
      </Box>
    );
  },
);

import { SearchForm } from "../Search/Form";
import { SearchWidgetHandler } from "./Handler";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Region").Region} [region]
 * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
 * @property {boolean} [withSort]
 * @property {boolean} [withFilters]
 * @property {boolean} [withDetailledSubmitButton]
 */
/**
 * @param {Props} props
 */
export function SearchWidget({
  region,
  department,
  withFilters = true,
  withDetailledSubmitButton = false,
}) {
  return (
    <SearchWidgetHandler region={region} department={department}>
      {({ form, submit }) => (
        <SearchForm
          region={region}
          department={department}
          withFilters={withFilters}
          withSort={false}
          withDetailledSubmitButton={withDetailledSubmitButton}
          submit={submit}
          form={form}
          appliedFilters={[]}
        />
      )}
    </SearchWidgetHandler>
  );
}

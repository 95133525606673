import { LEGACY_REGIONS } from "../../constants/legacyRegions";

/**
 * @param {object} params
 * @param {string} params.code
 * @returns {import("../../types/Region").RegionFront | undefined}
 */
export function getLegacyRegionByCode({ code }) {
  return LEGACY_REGIONS.find((region) => region.code === code);
}

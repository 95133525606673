import { Button, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { forwardRef } from "react";

export const OtaTypesPicker = forwardRef(
  /**
   * @typedef {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<number[]> & { otaTypes: import("@raiden/library-ui/types/ota/Type").OtaType[]}} FieldProps
   */
  /**
   * @param {FieldProps} props
   */

  function OtaTypesPicker(
    { name, value, onChange, isDisabled, otaTypes },
    ref,
  ) {
    const translate = useTranslate();

    return (
      <>
        {otaTypes.map((otaType) => (
          <Button
            ref={ref}
            key={otaType.id}
            variant="outline"
            isDisabled={isDisabled}
            onClick={() => {
              onChange({
                target: {
                  name,
                  value: value.includes(otaType.id)
                    ? value.filter((id) => id !== otaType.id)
                    : [...value, otaType.id],
                },
              });
            }}
            borderRadius="full"
            colorScheme={
              value.includes(otaType.id) ? "brandSecondary" : undefined
            }>
            <Text variant="text-xs" color="inherit">
              {translate(otaType.name)}
            </Text>
          </Button>
        ))}
      </>
    );
  },
);

// deps
import { IoPauseOutline, IoPlayOutline } from "react-icons/io5";
import { defineMessage } from "react-intl";
import generateFrontUri from "../libraries/utils/generateFrontUrl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_RESTORE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

const HOTELS_SEARCH_TABS_VALUE_ALL = {
  value: "all",
  fields: [],
  defaultFields: [],
  colors: null,
  metaKey: "count",
};

export const HOTELS_SEARCH_TABS = [HOTELS_SEARCH_TABS_VALUE_ALL];

export const HOTELS_SEARCH_TABS_MESSAGE = defineMessage({
  defaultMessage: "{tab, select, all {Tous} trash {Archive} other {{tab}}}",
  description: "tab",
});

/* Tri */
export const HOTELS_SORT_VALUE_CREATED_ASC = "created_asc";
export const HOTELS_SORT_VALUE_CREATED_DESC = "created_desc";

export const hotelsSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} name_asc {Nom croissant} name_desc {Nom décroissant} other {{sort}}}",
});

export const HOTELS_SORTS = {
  [HOTELS_SORT_VALUE_CREATED_ASC]: {
    id: HOTELS_SORT_VALUE_CREATED_ASC,
  },
  [HOTELS_SORT_VALUE_CREATED_DESC]: {
    id: HOTELS_SORT_VALUE_CREATED_DESC,
  },
};

export const HOTELS_SORT_LIST = Object.values(HOTELS_SORTS);

export const HOTELS_LANGUAGE_VALUE_SPANISH = "ES";

export const HOTELS_LANGUAGE_VALUE_ENGLISH = "GB";

export const HOTELS_LANGUAGE_VALUE_FRENCH = "FR";

export const HOTELS_LANGUAGE_VALUE_GERMAN = "DE";

export const HOTELS_LANGUAGE_VALUE_ITALIAN = "IT";

export const HOTELS_LANGUAGE_VALUE_DUTCH = "NL";

export const HOTELS_LANGUAGE_VALUE_PORTUGUESE = "PT";

export const HOTELS_LANGUAGE_VALUE_RUSSIAN = "RU";

export const HOTELS_LANGUAGE_VALUE_ARAB = "AE";

export const HOTELS_LANGUAGE_VALUE_CANADIAN = "CA";

export const HOTELS_LANGUAGE_VALUE_CHINESE = "CN";

export const HOTELS_LANGUAGE_VALUE_JAPANESE = "JP";

export const HOTELS_LANGUAGE_VALUE_DANISH = "DA";
export const HOTELS_LANGUAGE_VALUE_SWEDISH = "SE";
export const HOTELS_LANGUAGE_VALUE_POLISH = "PL";
export const HOTELS_LANGUAGE_VALUE_GREEK = "GR";
export const HOTELS_LANGUAGE_VALUE_NORWEGIAN = "NO";

export const HOTELS_LANGUAGES = {
  [HOTELS_LANGUAGE_VALUE_SPANISH]: {
    id: HOTELS_LANGUAGE_VALUE_SPANISH,
  },
  [HOTELS_LANGUAGE_VALUE_ENGLISH]: {
    id: HOTELS_LANGUAGE_VALUE_ENGLISH,
  },
  [HOTELS_LANGUAGE_VALUE_FRENCH]: {
    id: HOTELS_LANGUAGE_VALUE_FRENCH,
  },
  [HOTELS_LANGUAGE_VALUE_GERMAN]: {
    id: HOTELS_LANGUAGE_VALUE_GERMAN,
  },
  [HOTELS_LANGUAGE_VALUE_ITALIAN]: {
    id: HOTELS_LANGUAGE_VALUE_ITALIAN,
  },
  [HOTELS_LANGUAGE_VALUE_DUTCH]: {
    id: HOTELS_LANGUAGE_VALUE_DUTCH,
  },
  [HOTELS_LANGUAGE_VALUE_PORTUGUESE]: {
    id: HOTELS_LANGUAGE_VALUE_PORTUGUESE,
  },
  [HOTELS_LANGUAGE_VALUE_RUSSIAN]: {
    id: HOTELS_LANGUAGE_VALUE_RUSSIAN,
  },
  [HOTELS_LANGUAGE_VALUE_ARAB]: {
    id: HOTELS_LANGUAGE_VALUE_ARAB,
  },
  [HOTELS_LANGUAGE_VALUE_CANADIAN]: {
    id: HOTELS_LANGUAGE_VALUE_CANADIAN,
  },
  [HOTELS_LANGUAGE_VALUE_CHINESE]: {
    id: HOTELS_LANGUAGE_VALUE_CHINESE,
  },
  [HOTELS_LANGUAGE_VALUE_JAPANESE]: {
    id: HOTELS_LANGUAGE_VALUE_JAPANESE,
  },
  [HOTELS_LANGUAGE_VALUE_DANISH]: {
    id: HOTELS_LANGUAGE_VALUE_DANISH,
  },
  [HOTELS_LANGUAGE_VALUE_SWEDISH]: {
    id: HOTELS_LANGUAGE_VALUE_SWEDISH,
  },
  [HOTELS_LANGUAGE_VALUE_POLISH]: {
    id: HOTELS_LANGUAGE_VALUE_POLISH,
  },
  [HOTELS_LANGUAGE_VALUE_GREEK]: {
    id: HOTELS_LANGUAGE_VALUE_GREEK,
  },
  [HOTELS_LANGUAGE_VALUE_NORWEGIAN]: {
    id: HOTELS_LANGUAGE_VALUE_NORWEGIAN,
  },
};

export const HOTELS_LANGUAGE_VALUE_LIST = Object.values(HOTELS_LANGUAGES);

export const hotelsLangMessage = defineMessage({
  defaultMessage: `{lang, select,
    ES {Espagnol}
    GB {Anglais}
    FR {Français}
    DE {Allemand}
    IT {Italien}
    NL {Néerlandais}
    PT {Portugais}
    RU {Russe}
    AE {Arabe}
    CA {Canadien}
    CN {Chinois}
    JP {Japonais}
    DA {Danois}
    SE {Suédois}
    PL {Polonais}
    GR {Grec}
    NO {Norvégien}
    other {inconnu}}`,
});

export const HOTELS_UPDATE_CANCELLATION_CONDITION_VALUE_NONE = "none";

export const HOTELS_UPDATE_CANCELLATION_CONDITION_VALUE_PARTNER = "partner";

/**
 * Liste des fuseaux horaires supportés par PHP (en provenance de l'espace propriétaire)
 * @see https://www.php.net/manual/en/timezones.php
 * @see https://pecl.php.net/get/timezonedb
 */
export const HOTELS_TIMEZONE_LIST = [
  "Africa/Abidjan",
  "Africa/Accra",
  "Africa/Addis_Ababa",
  "Africa/Algiers",
  "Africa/Asmara",
  "Africa/Bamako",
  "Africa/Bangui",
  "Africa/Banjul",
  "Africa/Bissau",
  "Africa/Blantyre",
  "Africa/Brazzaville",
  "Africa/Bujumbura",
  "Africa/Cairo",
  "Africa/Casablanca",
  "Africa/Ceuta",
  "Africa/Conakry",
  "Africa/Dakar",
  "Africa/Dar_es_Salaam",
  "Africa/Djibouti",
  "Africa/Douala",
  "Africa/El_Aaiun",
  "Africa/Freetown",
  "Africa/Gaborone",
  "Africa/Harare",
  "Africa/Johannesburg",
  "Africa/Juba",
  "Africa/Kampala",
  "Africa/Khartoum",
  "Africa/Kigali",
  "Africa/Kinshasa",
  "Africa/Lagos",
  "Africa/Libreville",
  "Africa/Lome",
  "Africa/Luanda",
  "Africa/Lubumbashi",
  "Africa/Lusaka",
  "Africa/Malabo",
  "Africa/Maputo",
  "Africa/Maseru",
  "Africa/Mbabane",
  "Africa/Mogadishu",
  "Africa/Monrovia",
  "Africa/Nairobi",
  "Africa/Ndjamena",
  "Africa/Niamey",
  "Africa/Nouakchott",
  "Africa/Ouagadougou",
  "Africa/Porto-Novo",
  "Africa/Sao_Tome",
  "Africa/Tripoli",
  "Africa/Tunis",
  "Africa/Windhoek",
  "America/Adak",
  "America/Anchorage",
  "America/Anguilla",
  "America/Antigua",
  "America/Araguaina",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca",
  "America/Argentina/Cordoba",
  "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta",
  "America/Argentina/San_Juan",
  "America/Argentina/San_Luis",
  "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia",
  "America/Aruba",
  "America/Asuncion",
  "America/Atikokan",
  "America/Bahia",
  "America/Bahia_Banderas",
  "America/Barbados",
  "America/Belem",
  "America/Belize",
  "America/Blanc-Sablon",
  "America/Boa_Vista",
  "America/Bogota",
  "America/Boise",
  "America/Cambridge_Bay",
  "America/Campo_Grande",
  "America/Cancun",
  "America/Caracas",
  "America/Cayenne",
  "America/Cayman",
  "America/Chicago",
  "America/Chihuahua",
  "America/Costa_Rica",
  "America/Creston",
  "America/Cuiaba",
  "America/Curacao",
  "America/Danmarkshavn",
  "America/Dawson",
  "America/Dawson_Creek",
  "America/Denver",
  "America/Detroit",
  "America/Dominica",
  "America/Edmonton",
  "America/Eirunepe",
  "America/El_Salvador",
  "America/Fort_Nelson",
  "America/Fortaleza",
  "America/Glace_Bay",
  "America/Goose_Bay",
  "America/Grand_Turk",
  "America/Grenada",
  "America/Guadeloupe",
  "America/Guatemala",
  "America/Guayaquil",
  "America/Guyana",
  "America/Halifax",
  "America/Havana",
  "America/Hermosillo",
  "America/Indiana/Indianapolis",
  "America/Indiana/Knox",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Tell_City",
  "America/Indiana/Vevay",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Inuvik",
  "America/Iqaluit",
  "America/Jamaica",
  "America/Juneau",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Kralendijk",
  "America/La_Paz",
  "America/Lima",
  "America/Los_Angeles",
  "America/Lower_Princes",
  "America/Maceio",
  "America/Managua",
  "America/Manaus",
  "America/Marigot",
  "America/Martinique",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Menominee",
  "America/Merida",
  "America/Metlakatla",
  "America/Mexico_City",
  "America/Miquelon",
  "America/Moncton",
  "America/Monterrey",
  "America/Montevideo",
  "America/Montserrat",
  "America/Nassau",
  "America/New_York",
  "America/Nipigon",
  "America/Nome",
  "America/Noronha",
  "America/North_Dakota/Beulah",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/Nuuk",
  "America/Ojinaga",
  "America/Panama",
  "America/Pangnirtung",
  "America/Paramaribo",
  "America/Phoenix",
  "America/Port-au-Prince",
  "America/Port_of_Spain",
  "America/Porto_Velho",
  "America/Puerto_Rico",
  "America/Punta_Arenas",
  "America/Rainy_River",
  "America/Rankin_Inlet",
  "America/Recife",
  "America/Regina",
  "America/Resolute",
  "America/Rio_Branco",
  "America/Santarem",
  "America/Santiago",
  "America/Santo_Domingo",
  "America/Sao_Paulo",
  "America/Scoresbysund",
  "America/Sitka",
  "America/St_Barthelemy",
  "America/St_Johns",
  "America/St_Kitts",
  "America/St_Lucia",
  "America/St_Thomas",
  "America/St_Vincent",
  "America/Swift_Current",
  "America/Tegucigalpa",
  "America/Thule",
  "America/Thunder_Bay",
  "America/Tijuana",
  "America/Toronto",
  "America/Tortola",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Winnipeg",
  "America/Yakutat",
  "America/Yellowknife",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Macquarie",
  "Antarctica/Mawson",
  "Antarctica/McMurdo",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "Arctic/Longyearbyen",
  "Asia/Aden",
  "Asia/Almaty",
  "Asia/Amman",
  "Asia/Anadyr",
  "Asia/Aqtau",
  "Asia/Aqtobe",
  "Asia/Ashgabat",
  "Asia/Atyrau",
  "Asia/Baghdad",
  "Asia/Bahrain",
  "Asia/Baku",
  "Asia/Bangkok",
  "Asia/Barnaul",
  "Asia/Beirut",
  "Asia/Bishkek",
  "Asia/Brunei",
  "Asia/Chita",
  "Asia/Choibalsan",
  "Asia/Colombo",
  "Asia/Damascus",
  "Asia/Dhaka",
  "Asia/Dili",
  "Asia/Dubai",
  "Asia/Dushanbe",
  "Asia/Famagusta",
  "Asia/Gaza",
  "Asia/Hebron",
  "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong",
  "Asia/Hovd",
  "Asia/Irkutsk",
  "Asia/Jakarta",
  "Asia/Jayapura",
  "Asia/Jerusalem",
  "Asia/Kabul",
  "Asia/Kamchatka",
  "Asia/Karachi",
  "Asia/Kathmandu",
  "Asia/Khandyga",
  "Asia/Kolkata",
  "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Asia/Kuwait",
  "Asia/Macau",
  "Asia/Magadan",
  "Asia/Makassar",
  "Asia/Manila",
  "Asia/Muscat",
  "Asia/Nicosia",
  "Asia/Novokuznetsk",
  "Asia/Novosibirsk",
  "Asia/Omsk",
  "Asia/Oral",
  "Asia/Phnom_Penh",
  "Asia/Pontianak",
  "Asia/Pyongyang",
  "Asia/Qatar",
  "Asia/Qostanay",
  "Asia/Qyzylorda",
  "Asia/Riyadh",
  "Asia/Sakhalin",
  "Asia/Samarkand",
  "Asia/Seoul",
  "Asia/Shanghai",
  "Asia/Singapore",
  "Asia/Srednekolymsk",
  "Asia/Taipei",
  "Asia/Tashkent",
  "Asia/Tbilisi",
  "Asia/Tehran",
  "Asia/Thimphu",
  "Asia/Tokyo",
  "Asia/Tomsk",
  "Asia/Ulaanbaatar",
  "Asia/Urumqi",
  "Asia/Ust-Nera",
  "Asia/Vientiane",
  "Asia/Vladivostok",
  "Asia/Yakutsk",
  "Asia/Yangon",
  "Asia/Yekaterinburg",
  "Asia/Yerevan",
  "Atlantic/Azores",
  "Atlantic/Bermuda",
  "Atlantic/Canary",
  "Atlantic/Cape_Verde",
  "Atlantic/Faroe",
  "Atlantic/Madeira",
  "Atlantic/Reykjavik",
  "Atlantic/South_Georgia",
  "Atlantic/St_Helena",
  "Atlantic/Stanley",
  "Australia/Adelaide",
  "Australia/Brisbane",
  "Australia/Broken_Hill",
  "Australia/Currie",
  "Australia/Darwin",
  "Australia/Eucla",
  "Australia/Hobart",
  "Australia/Lindeman",
  "Australia/Lord_Howe",
  "Australia/Melbourne",
  "Australia/Perth",
  "Australia/Sydney",
  "Europe/Amsterdam",
  "Europe/Andorra",
  "Europe/Astrakhan",
  "Europe/Athens",
  "Europe/Belgrade",
  "Europe/Berlin",
  "Europe/Bratislava",
  "Europe/Brussels",
  "Europe/Bucharest",
  "Europe/Budapest",
  "Europe/Busingen",
  "Europe/Chisinau",
  "Europe/Copenhagen",
  "Europe/Dublin",
  "Europe/Gibraltar",
  "Europe/Guernsey",
  "Europe/Helsinki",
  "Europe/Isle_of_Man",
  "Europe/Istanbul",
  "Europe/Jersey",
  "Europe/Kaliningrad",
  "Europe/Kiev",
  "Europe/Kirov",
  "Europe/Lisbon",
  "Europe/Ljubljana",
  "Europe/London",
  "Europe/Luxembourg",
  "Europe/Madrid",
  "Europe/Malta",
  "Europe/Mariehamn",
  "Europe/Minsk",
  "Europe/Monaco",
  "Europe/Moscow",
  "Europe/Oslo",
  "Europe/Paris",
  "Europe/Podgorica",
  "Europe/Prague",
  "Europe/Riga",
  "Europe/Rome",
  "Europe/Samara",
  "Europe/San_Marino",
  "Europe/Sarajevo",
  "Europe/Saratov",
  "Europe/Simferopol",
  "Europe/Skopje",
  "Europe/Sofia",
  "Europe/Stockholm",
  "Europe/Tallinn",
  "Europe/Tirane",
  "Europe/Ulyanovsk",
  "Europe/Uzhgorod",
  "Europe/Vaduz",
  "Europe/Vatican",
  "Europe/Vienna",
  "Europe/Vilnius",
  "Europe/Volgograd",
  "Europe/Warsaw",
  "Europe/Zagreb",
  "Europe/Zaporozhye",
  "Europe/Zurich",
  "Indian/Antananarivo",
  "Indian/Chagos",
  "Indian/Christmas",
  "Indian/Cocos",
  "Indian/Comoro",
  "Indian/Kerguelen",
  "Indian/Mahe",
  "Indian/Maldives",
  "Indian/Mauritius",
  "Indian/Mayotte",
  "Indian/Reunion",
  "Pacific/Apia",
  "Pacific/Auckland",
  "Pacific/Bougainville",
  "Pacific/Chatham",
  "Pacific/Chuuk",
  "Pacific/Easter",
  "Pacific/Efate",
  "Pacific/Enderbury",
  "Pacific/Fakaofo",
  "Pacific/Fiji",
  "Pacific/Funafuti",
  "Pacific/Galapagos",
  "Pacific/Gambier",
  "Pacific/Guadalcanal",
  "Pacific/Guam",
  "Pacific/Honolulu",
  "Pacific/Kiritimati",
  "Pacific/Kosrae",
  "Pacific/Kwajalein",
  "Pacific/Majuro",
  "Pacific/Marquesas",
  "Pacific/Midway",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Norfolk",
  "Pacific/Noumea",
  "Pacific/Pago_Pago",
  "Pacific/Palau",
  "Pacific/Pitcairn",
  "Pacific/Pohnpei",
  "Pacific/Port_Moresby",
  "Pacific/Rarotonga",
  "Pacific/Saipan",
  "Pacific/Tahiti",
  "Pacific/Tarawa",
  "Pacific/Tongatapu",
  "Pacific/Wake",
  "Pacific/Wallis",
  "UTC",
];

// From there, new hotel, see what we keep or not before this line

export const HOTELS_TYPE_VALUE_COTTAGE = "cottage";
export const HOTELS_TYPE_VALUE_GROUP = "group";
export const HOTELS_TYPE_VALUE_GUEST_HOUSES = "guest_house";
export const HOTELS_TYPE_VALUE_UNUSUAL = "unusual";
export const HOTELS_TYPE_VALUE_VILLAGE = "village";
export const HOTELS_TYPE_VALUE_RESIDENCE = "residence";

/**
 * @typedef {object} HotelTypeData
 * @property {import("../types/Hotel").HotelType} id
 * @property {string} src
 * @property {string} icon
 * @property {string} colorChart
 */
/** @type {Record<import("../types/Hotel").HotelType, HotelTypeData>} */
export const HOTELS_TYPES = {
  [HOTELS_TYPE_VALUE_COTTAGE]: {
    id: HOTELS_TYPE_VALUE_COTTAGE,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/cottage.webp" },
      includeBasePath: true,
    }),
    icon: "ms_gite",
    colorChart: "#2F855A",
  },
  [HOTELS_TYPE_VALUE_GROUP]: {
    id: HOTELS_TYPE_VALUE_GROUP,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/group.webp" },
      includeBasePath: true,
    }),
    icon: "ms_groups",
    colorChart: "#664BBA",
  },
  [HOTELS_TYPE_VALUE_GUEST_HOUSES]: {
    id: HOTELS_TYPE_VALUE_GUEST_HOUSES,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/guest_house.webp" },
      includeBasePath: true,
    }),
    icon: "ms_location_away",
    colorChart: "#C05621",
  },
  [HOTELS_TYPE_VALUE_UNUSUAL]: {
    id: HOTELS_TYPE_VALUE_UNUSUAL,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/unusual.webp" },
      includeBasePath: true,
    }),
    icon: "ms_houseboat",
    colorChart: "#2C7A7B",
  },
  [HOTELS_TYPE_VALUE_VILLAGE]: {
    id: HOTELS_TYPE_VALUE_VILLAGE,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/village.webp" },
      includeBasePath: true,
    }),
    icon: "ms_holiday_village",
    colorChart: "#AB3F7E",
  },
  [HOTELS_TYPE_VALUE_RESIDENCE]: {
    id: HOTELS_TYPE_VALUE_RESIDENCE,
    src: generateFrontUri({
      id: "@front.internal-assets",
      parameters: { filePath: "hotel-types/residence.webp" },
      includeBasePath: true,
    }),
    icon: "ms_domain",
    colorChart: "#666",
  },
};

export const HOTELS_TYPES_VALUE_LIST = Object.values(HOTELS_TYPES);

export const HOTELS_TYPES_VALUES = HOTELS_TYPES_VALUE_LIST.map(
  (hotelType) => hotelType.id,
);

export const hotelsTypeMessage = defineMessage({
  defaultMessage: `{type, select,
     cottage {Gîte}
     group {Gîte de groupe}
     guest_house {Chambre d'hôte}
     unusual {Insolite}
     village {Village}
     residence {Résidence}
     other {{type}}}`,
});

/* Message specific for front */
export const hotelsTypeSearchMessage = defineMessage({
  defaultMessage: `{type, select,
     cottage {Location de vacances}
     group {Gîte de groupe}
     guest_house {Chambre d'hôtes / Chambre Clévacances}
     unusual {Insolite}
     village {Village}
     residence {Résidence}
     other {{type}}}`,
});

export const hotelsTypeMessagePlural = defineMessage({
  defaultMessage: `{type, select,
     cottage {Gîtes}
     group {Gîtes de groupes}
     guest_house {Chambres d'hôtes}
     unusual {Insolites}
     village {Villages}
     residence {Résidences}
     other {{type}}}`,
});

export const HOTELS_STATE_VALUE_DRAFT = "draft";
export const HOTELS_STATE_VALUE_ENABLED = "enabled";
export const HOTELS_STATE_VALUE_DISABLED = "disabled";

export const HOTELS_STATES = {
  [HOTELS_STATE_VALUE_ENABLED]: {
    id: HOTELS_STATE_VALUE_ENABLED,
    colors: ["green.600", "green.300"],
    color: "green",
    colorChart: "#2F855A",
    icon: IoPlayOutline,
  },
  [HOTELS_STATE_VALUE_DRAFT]: {
    id: HOTELS_STATE_VALUE_DRAFT,
    colors: ["orange.600", "orange.300"],
    color: "orange",
    colorChart: "#C05621",
    icon: IoPauseOutline,
  },
  [HOTELS_STATE_VALUE_DISABLED]: {
    id: HOTELS_STATE_VALUE_DISABLED,
    colors: ["purple.600", "purple.300"],
    color: "purple",
    colorChart: "#664BBA",
    icon: IoPauseOutline,
  },
};

export const HOTELS_STATE_VALUE_LIST = Object.values(HOTELS_STATES);

export const hotelStatesMessage = defineMessage({
  defaultMessage:
    "{state, select, pending {brouillon} enabled {activé} disabled {désactivé} draft {brouillon} other {inconnu}}",
});

export const HOTELS_PAYMENT_MODES_VALUE_CB = "cb";
export const HOTELS_PAYMENT_MODES_VALUE_CHQ = "chq";
export const HOTELS_PAYMENT_MODES_VALUE_VIR = "vir";
export const HOTELS_PAYMENT_MODES_VALUE_ANCV = "ancv";
export const HOTELS_PAYMENT_MODES_VALUE_ESP = "esp";
export const HOTELS_PAYMENT_MODES_VALUE_EANCV = "eancv";
export const HOTELS_PAYMENT_MODES_VALUE_PAYPAL = "paypal";

export const HOTELS_PAYMENT_MODES = {
  [HOTELS_PAYMENT_MODES_VALUE_CHQ]: {
    id: HOTELS_PAYMENT_MODES_VALUE_CHQ,
  },
  [HOTELS_PAYMENT_MODES_VALUE_VIR]: {
    id: HOTELS_PAYMENT_MODES_VALUE_VIR,
  },
  [HOTELS_PAYMENT_MODES_VALUE_ANCV]: {
    id: HOTELS_PAYMENT_MODES_VALUE_ANCV,
  },
  [HOTELS_PAYMENT_MODES_VALUE_ESP]: {
    id: HOTELS_PAYMENT_MODES_VALUE_ESP,
  },
  [HOTELS_PAYMENT_MODES_VALUE_EANCV]: {
    id: HOTELS_PAYMENT_MODES_VALUE_EANCV,
  },

  [HOTELS_PAYMENT_MODES_VALUE_PAYPAL]: {
    id: HOTELS_PAYMENT_MODES_VALUE_PAYPAL,
  },
  [HOTELS_PAYMENT_MODES_VALUE_CB]: {
    id: HOTELS_PAYMENT_MODES_VALUE_CB,
  },
};

export const HOTELS_PAYMENT_MODES_VALUE_LIST =
  Object.values(HOTELS_PAYMENT_MODES);

export const hotelsPaymentModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    cb {Carte bancaire}
    chq {Chèque}
    vir {Virement}
    ancv {Chèques vacances}
    esp {Espèces}
    eancv {Chèques vacances ANCV Connect}
    paypal {Paypal}
    other {inconnu}}`,
});

export const hotelsPaymentModeAlternativeMessage = defineMessage({
  defaultMessage: `{mode, select,
    cb {par carte bancaire}
    chq {par chèque}
    vir {par virement}
    ancv {par chèques-vacances}
    esp {en espèces}
    eancv {par chèques-vacances connect}
    paypal {par Paypal}
    other {inconnu}}`,
});

export const hotelsBookingEnabledDisabledMessage = defineMessage({
  defaultMessage: `{isBookingEnabled, select,
    true {Réservation en ligne activée}
    other {Réservation en ligne désactivée}
     }`,
});

export const HOTELS_MEDIA_TYPE_VALUE_VIDEO = "video";
export const HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT = "virtual_visit";

export const HOTELS_MEDIAS_TYPES = {
  [HOTELS_MEDIA_TYPE_VALUE_VIDEO]: {
    id: HOTELS_MEDIA_TYPE_VALUE_VIDEO,
  },
  [HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT]: {
    id: HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT,
  },
};

export const HOTELS_MEDIA_TYPE_VALUE_LIST = Object.values(HOTELS_MEDIAS_TYPES);

export const hotelsMediaTypeMessage = defineMessage({
  defaultMessage: `{type, select,
    video {Vidéo}
    virtual_visit {Visite virtuelle}
    other {inconnu}}`,
});

export const HOTELS_CONTENT_ALIAS_VALUE_CONTRACT = "contract";
export const HOTELS_CONTENT_ALIAS_VALUE_INVOICE = "invoice";
export const HOTELS_CONTENT_ALIAS_VALUE_CERTIFICATE = "certificate";
export const HOTELS_CONTENT_ALIAS_VALUE_DOCUMENT = "document";
export const HOTELS_CONTENT_ALIAS_VALUE_CONTRACT_INFOS = "contract_infos";
export const HOTELS_CONTENT_ALIAS_VALUE_SELLING_TERMS = "selling_terms";

export const HOTELS_CONTENTS_ALIASES = {
  [HOTELS_CONTENT_ALIAS_VALUE_CONTRACT]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_CONTRACT,
    placeholder: defineMessage({
      defaultMessage:
        "Ex : Bonjour, suite à la réception de votre règlement, votre réservation est confirmée.Nous avons le plaisir de vous adresser ce contrat de location.cordialement.",
    }),
  },
  [HOTELS_CONTENT_ALIAS_VALUE_INVOICE]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_INVOICE,
    placeholder: defineMessage({
      defaultMessage:
        "Ex : Bonjour, veuillez trouver ci-joint la facture correspondant à votre séjour.Cordialement.",
    }),
  },
  [HOTELS_CONTENT_ALIAS_VALUE_CERTIFICATE]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_CERTIFICATE,
    placeholder: defineMessage({
      defaultMessage:
        "Ex : Bonjour, nous avons le plaisir de vous adresser cette attestation de séjour, cordialement.",
    }),
  },
  [HOTELS_CONTENT_ALIAS_VALUE_DOCUMENT]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_DOCUMENT,
    placeholder: defineMessage({
      defaultMessage:
        "Ex : Bonjour, veuillez trouver ci-joint un document lié à votre réservation.cordialement.",
    }),
  },
  [HOTELS_CONTENT_ALIAS_VALUE_CONTRACT_INFOS]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_CONTRACT_INFOS,
    placeholder: defineMessage({
      defaultMessage:
        "Toute information complémentaire que vous souhaitez faire apparaitre sur le contrat de location, par exemple location non fumeur, fêtes interdites, charges non comprises.",
    }),
  },
  [HOTELS_CONTENT_ALIAS_VALUE_SELLING_TERMS]: {
    id: HOTELS_CONTENT_ALIAS_VALUE_SELLING_TERMS,
    placeholder: defineMessage({
      defaultMessage:
        "Précisez les C.G.V. que vous appliquez pour vos contrats de réservations en direct.",
    }),
  },
};

export const HOTELS_CONTENTS_ALIASES_LIST = Object.values(
  HOTELS_CONTENTS_ALIASES,
);

export const hotelsContentAliasesMessage = defineMessage({
  defaultMessage: `{alias, select,
    contract {Contrat}
    invoice {Facture}
    certificate {Attestation de séjour}
    document {Document}
    contract_infos {Précisions contrat}
    selling_terms {Conditions générales de vente}
    other {inconnu}}`,
});

export const hotelsContentAliasesFieldLabelMessage = defineMessage({
  defaultMessage: `{alias, select,
    contract {Texte du contrat}
    invoice {Texte de la facture}
    certificate {Texte de l'attestation de séjour}
    document {Texte des documents}
    contract_infos {Informations complémentaires}
    selling_terms {Conditions Générales de Vente pour contrat direct}
    other {inconnu}}`,
});

export const HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_HOUR = "H";
export const HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_DAY = "J";

export const HOTEL_BOOKING_CONFIGURATION_DELAYS = {
  [HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_HOUR]: {
    id: HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_HOUR,
  },
  [HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_DAY]: {
    id: HOTEL_BOOKING_CONFIGURATION_DELAY_VALUE_DAY,
  },
};

export const HOTEL_BOOKING_CONFIGURATION_DELAY_LIST = Object.values(
  HOTEL_BOOKING_CONFIGURATION_DELAYS,
);

export const hotelBookingConfigurationDelayMessage = defineMessage({
  defaultMessage: `{delay, select,
    H {Heures}
    J {Jour}
    other {{delay}}
  }`,
});

export const HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER = "never";
export const HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_EVER = "ever";

export const HOTEL_OWNER_ACKNOWLEDGMENT_MODES = {
  [HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER]: {
    id: HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER,
  },
  [HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_EVER]: {
    id: HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_EVER,
  },
};

export const HOTEL_OWNER_ACKNOWLEDGMENT_MODE_LIST = Object.values(
  HOTEL_OWNER_ACKNOWLEDGMENT_MODES,
);

export const hotelOwnerAcknowledgmentModeMessage = defineMessage({
  defaultMessage: `{mode, select,
    never {Réservation instantanée}
    ever {Réservation avec délai d'acceptation}
    other {{mode}}
  }`,
});

export const HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_ACCEPT = "accept";
export const HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_REJECT = "reject";

export const HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTIONS = {
  [HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_ACCEPT]: {
    id: HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_ACCEPT,
  },
  [HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_REJECT]: {
    id: HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_VALUE_REJECT,
  },
};

export const HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTION_LIST = Object.values(
  HOTEL_OWNER_ACKNOWLEDGMENT_DEFAULT_ACTIONS,
);

export const hotelOwnerAcknowledgmentDefaultActionMessage = defineMessage({
  defaultMessage: `{action, select,
    accept {Accepter automatiquement la réservation}
    reject {Refuser automatiquement la réservation}
    other {{action}}
  }`,
});

export const HOTEL_POLICIES_ACTIONS = {
  restore: {
    policies: [POLICIES_POLICY_VALUE_RESTORE],
  },
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_SOFT_DELETE,
      POLICIES_POLICY_VALUE_DELETE,
    ],
  },
};

export const HOTEL_FILTER_AVAILABILITIES_VALUE_WITH_AVAILABILITIES = "with";
export const HOTEL_FILTER_AVAILABILITIES_VALUE_WITHOUT_AVAILABILITIES =
  "without";

export const HOTEL_FILTER_AVAILABILITIES_VALUES = {
  [HOTEL_FILTER_AVAILABILITIES_VALUE_WITH_AVAILABILITIES]: {
    id: HOTEL_FILTER_AVAILABILITIES_VALUE_WITH_AVAILABILITIES,
  },
  [HOTEL_FILTER_AVAILABILITIES_VALUE_WITHOUT_AVAILABILITIES]: {
    id: HOTEL_FILTER_AVAILABILITIES_VALUE_WITHOUT_AVAILABILITIES,
  },
};

export const HOTEL_FILTER_AVAILABILITIES_VALUE_LIST = Object.values(
  HOTEL_FILTER_AVAILABILITIES_VALUES,
);

export const hotelFilterAvailabilitiesValuesMessage = defineMessage({
  defaultMessage: `{availability, select,
    with {possède des disponibilités}
    without {ne possède pas des disponibilités}
    other {{availability}}
  }`,
});

export const HOTEL_FILTER_SEASONS_VALUE_WITH_SEASONS = "with";
export const HOTEL_FILTER_SEASONS_VALUE_WITHOUT_SEASONS = "without";

export const HOTEL_FILTER_SEASONS_VALUES = {
  [HOTEL_FILTER_SEASONS_VALUE_WITH_SEASONS]: {
    id: HOTEL_FILTER_SEASONS_VALUE_WITH_SEASONS,
  },
  [HOTEL_FILTER_SEASONS_VALUE_WITHOUT_SEASONS]: {
    id: HOTEL_FILTER_SEASONS_VALUE_WITHOUT_SEASONS,
  },
};

export const HOTEL_FILTER_SEASONS_VALUE_LIST = Object.values(
  HOTEL_FILTER_SEASONS_VALUES,
);

export const hotelFilterSeasonsMessage = defineMessage({
  defaultMessage: `{season, select,
    with {possède des tarifs}
    without {ne possède pas des tarifs}
    other {{season}}
  }`,
});

export const HOTEL_BUSINESS_TYPE_VALUE_RANKING = "ranking";
export const HOTEL_BUSINESS_TYPE_VALUE_LABEL = "label";
export const HOTEL_BUSINESS_TYPE_VALUE_BOTH = "both";

export const HOTEL_BUSINESS_TYPES = {
  [HOTEL_BUSINESS_TYPE_VALUE_RANKING]: {
    id: HOTEL_BUSINESS_TYPE_VALUE_RANKING,
  },
  [HOTEL_BUSINESS_TYPE_VALUE_LABEL]: {
    id: HOTEL_BUSINESS_TYPE_VALUE_LABEL,
  },
  [HOTEL_BUSINESS_TYPE_VALUE_BOTH]: {
    id: HOTEL_BUSINESS_TYPE_VALUE_BOTH,
  },
};

export const HOTEL_BUSINESS_TYPE_VALUE_LIST =
  Object.values(HOTEL_BUSINESS_TYPES);

export const hotelBusinessTypeMessage = defineMessage({
  defaultMessage: `{type, select,
    ranking {Classement}
    label {Labellisation}
    both {Classement et labellisation}
    other {{type}}
  }`,
});

export const HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_NEED_COMMUNICATION =
  "need_communication";
export const HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_REFUSE_ONLINE_PAYMENT =
  "refuse_online_payment";
export const HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_PMS = "other_pms";
export const HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_CHANNEL_MANAGER =
  "other_channel_manager";

export const HOTELS_ON_DEMAND_REASONS = {
  [HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_NEED_COMMUNICATION]: {
    id: HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_NEED_COMMUNICATION,
  },
  [HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_REFUSE_ONLINE_PAYMENT]: {
    id: HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_REFUSE_ONLINE_PAYMENT,
  },
  [HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_PMS]: {
    id: HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_PMS,
  },
  [HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_CHANNEL_MANAGER]: {
    id: HOTELS_ON_DEMAND_DISABLED_REASON_VALUE_OTHER_CHANNEL_MANAGER,
  },
};

export const HOTELS_ON_DEMAND_REASON_LIST = Object.values(
  HOTELS_ON_DEMAND_REASONS,
);

export const hotelsOnDemandDisabledReasonMessage = defineMessage({
  defaultMessage: `{reason, select,
    need_communication {souhaite échanger avec le voyageur au préalable avant de valider}
    refuse_online_payment {ne souhaite pas du tout de paiement en ligne, gère en direct exclusivement}
    other_pms {gère les réservations via leur propre centrale de réservation}
    other_channel_manager {gère les réservations via leur propre channel manager}
    other {{reason}}
  }`,
});

export const HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_CITY = "invalid_city";
export const HOTELS_LOCALIZATION_ERROR_VALUE_INSEE_NOT_FOUND =
  "insee_not_found";
export const HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_INSEE = "invalid_insee";

export const HOTELS_LOCALIZATION_ERRORS = {
  [HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_CITY]: {
    id: HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_CITY,
  },
  [HOTELS_LOCALIZATION_ERROR_VALUE_INSEE_NOT_FOUND]: {
    id: HOTELS_LOCALIZATION_ERROR_VALUE_INSEE_NOT_FOUND,
  },
  [HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_INSEE]: {
    id: HOTELS_LOCALIZATION_ERROR_VALUE_INVALID_INSEE,
  },
};

export const hotelLocalizationErrorMessage = defineMessage({
  defaultMessage:
    "{localizationError, select, invalid_city {Ville invalide} insee_not_found {Code insee introuvable à partir du code postal et de la ville} invalid_insee {Code INSEE invalide} other {}}",
});

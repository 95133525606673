import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_BLACKLIST_CONTACTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_MESSAGES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_GUESTS_CONTACTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_BATCH_ROOMS,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_TRAVELERS_VIEW_ANY,
} from "@raiden/library-ui/constants/authorizations";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { defineMessage } from "react-intl";
import uniqid from "uniqid";
import customerHasMoreThanOneHotel from "@raiden/library-ui/helpers/customers/hasMoreThanOneHotel";

/** @type {import("../components/Menu/MenuItemCore").MenuItemCoreDeclaration[]} */
const _MENU_ITEMS = [
  {
    title: defineMessage({ defaultMessage: "Accueil" }),
    icon: "ms_home",
    href: generateFrontPath({ id: "@front.account" }),
    isHighlighted: true,
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams === generateFrontPath({ id: "@front.account" }),
  },
  {
    title: defineMessage({ defaultMessage: "Mes biens" }),
    icon: "ms_holiday_village",
    href: generateFrontPath({ id: "@front.account.hotels" }),
    isHighlighted: true,
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateFrontPath({ id: "@front.account.hotels" }),
      ),
    validator: ({ canPerform, userCustomer }) => {
      return (
        canPerform({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW_ANY,
        }) &&
        customerHasMoreThanOneHotel({ roomsInfos: userCustomer?.rooms_infos })
      );
    },
  },
  {
    title: defineMessage({ defaultMessage: "Mon bien" }),
    icon: "ms_house",
    href: generateFrontPath({ id: "@front.account.hotels" }),
    isHighlighted: true,
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateFrontPath({ id: "@front.account.hotels" }),
      ),
    validator: ({ canPerform, userCustomer }) => {
      return (
        canPerform({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_VIEW,
        }) &&
        !customerHasMoreThanOneHotel({ roomsInfos: userCustomer?.rooms_infos })
      );
    },
  },
  {
    title: defineMessage({ defaultMessage: "Disponibilités" }),
    icon: "ms_calendar_month",
    href: generateFrontPath({ id: "@front.account.rooms-availabilities" }),
    isHighlighted: true,
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateFrontPath({ id: "@front.account.rooms-availabilities" }),
      ),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_AVAILABILITIES_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Réservations" }),
    icon: "ms_book",
    href: generateFrontPath({ id: "@front.account.bookings" }),
    isHighlighted: true,
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams ===
      generateFrontPath({ id: "@front.account.bookings" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_BOOKINGS_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Retour au site" }),
    icon: "ms_public",
    href: generateFrontPath({ id: "@front.home" }),
    isActive: () => false,
  },
  {
    title: defineMessage({ defaultMessage: "Accéder au Wiki" }),
    icon: "ms_dictionary",
    href: "https://wiki.clevacances.com/",
    target: "_blank",
  },
  {
    title: defineMessage({ defaultMessage: "Assurance annulation" }),
    icon: "ms_health_and_safety",
    href: "https://clevacances.xplorassur.com/nos-offres_blank",
    target: "_blank",
  },
  {
    title: defineMessage({ defaultMessage: "Les actualités" }),
    description: defineMessage({
      defaultMessage: "Conseils, partenaires, ...",
    }),
    icon: "ms_newspaper",
    href: generateFrontPath({ id: "@front.account.news" }),
  },
  {
    title: defineMessage({ defaultMessage: "Suppléments" }),
    description: defineMessage({
      defaultMessage: "Suppléments sur les réservations",
    }),
    icon: "ms_add",
    href: generateFrontPath({ id: "@front.account.services" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_SERVICES_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Promotions" }),
    description: defineMessage({
      defaultMessage: "Promotions sur les réservations",
    }),
    icon: "ms_percent",
    href: generateFrontPath({ id: "@front.account.discounts" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DISCOUNTS_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Documents" }),
    description: defineMessage({
      defaultMessage: "Documents de réservation",
    }),
    icon: "ms_description",
    href: generateFrontPath({ id: "@front.account.documents" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_ROOMS_DOCUMENTS_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Mes clients" }),
    description: defineMessage({
      defaultMessage: "Liste et informations sur vos clients",
    }),
    icon: "ms_person_search",
    href: generateFrontPath({ id: "@front.account.customerTravelers" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_CUSTOMERS_TRAVELERS_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Demandes de contacts" }),
    description: defineMessage({
      defaultMessage: "Liste des demandes de contacts",
    }),
    icon: "ms_contact_page",
    href: generateFrontPath({ id: "@front.account.guestsContacts" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_HOTELS_GUESTS_CONTACTS_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Distribution" }),
    description: defineMessage({
      defaultMessage: "Diffuser mes offres",
    }),
    icon: "ms_p2p",
    href: generateFrontPath({ id: "@front.account.partners" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_BATCH_ROOMS,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Messagerie" }),
    description: defineMessage({
      defaultMessage: "Conversations",
    }),
    icon: "ms_mail",
    href: generateFrontPath({ id: "@front.account.messages" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_MESSAGES_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Avis client" }),
    description: defineMessage({
      defaultMessage: "Avis déposés par vos voyageurs",
    }),
    icon: "ms_reviews",
    href: generateFrontPath({ id: "@front.account.reviews" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_CARTS_BOOKING_REVIEW_VIEW_ANY,
      });
    },
  },
  {
    title: defineMessage({ defaultMessage: "Base arnaque" }),
    description: defineMessage({
      defaultMessage: "Liste des emails et téléphones frauduleux",
    }),
    icon: "ms_phishing",
    href: generateFrontPath({ id: "@front.account.blacklistContacts" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations:
          AUTHORIZATIONS_AUTHORIZATION_VALUE_BLACKLIST_CONTACTS_VIEW_ANY,
      });
    },
  },

  {
    title: defineMessage({ defaultMessage: "Statistiques" }),
    description: defineMessage({
      defaultMessage: "Nombre de vues, de réservations, ...",
    }),
    icon: "ms_bar_chart",
    href: generateFrontPath({ id: "@front.account.metrics" }),
    validator: ({ canPerform }) => {
      return canPerform({
        authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_METRICS_VIEW_ANY,
      });
    },
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../components/Menu/MenuItemCore").MenuItemCoreDeclaration[]} items
 * @param {number} [depth]
 * @returns {import("../components/Menu/MenuItemCore").MenuItemCore[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);

export const HIGHLIGHTED_MENU_ITEMS = MENU_ITEMS.filter(
  (item) => item.isHighlighted,
);

export const NOT_HIGHLIGHTED_MENU_ITEMS = MENU_ITEMS.filter(
  (item) => !item.isHighlighted,
);

export const HIGHLITHED_MENU_ITEMS_MIN_WIDTH = "71px";

import { Box, Image, Text } from "@chakra-ui/react";
import { RendererContext } from "@splitfire-agency/raiden-le-editeur/dist/renderers";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import PampaImage from "../../../components/PampaImage";
import generateAdminPath from "../../../libraries/utils/generateAdminPath";

export function FilesEditorImageBlockChakra(props) {
  const { block } = props;

  const { document, language } = useContext(RendererContext);

  const content =
    block.content.intl[language.key] ?? block.content.intl[document.language];

  const [hasError, setHasError] = useState(false);

  const intl = useIntl();

  return (
    <ConditionalWrapper
      hasWrapper={Boolean(block?.content?.href)}
      wrapper={function (children) {
        return (
          <a
            href={block?.content?.href}
            target={block?.content?.openInNewTab ? "_blank" : undefined}
            rel="noreferrer">
            {children}
          </a>
        );
      }}>
      {hasError || !content?.src ? (
        <Image
          src={generateAdminPath({
            id: "internal-assets",
            parameters: {
              filePath: `splashes/default.png`,
            },
            includeBasePath: true,
          })}
          alt={intl.formatMessage({ defaultMessage: "Placeholder" })}
        />
      ) : (
        <Box>
          <PampaImage
            alt={content?.alt}
            onLoad={function () {
              setHasError(false);
            }}
            onError={function () {
              setHasError(true);
            }}
            mode="crop"
            // @ts-ignore
            image={{
              pampa_uri: content?.src,
            }}
          />
          {content?.caption && <Text mt="0.5rem">{content.caption}</Text>}
        </Box>
      )}
    </ConditionalWrapper>
  );
}

FilesEditorImageBlockChakra.propTypes = {
  block: PropTypes.object,
};

import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { CampaignSeoBlock } from "../../../../components/Campaigns/SeoBlock";

function getUrlCampaignsFooter() {
  return generateApiUrl({
    id: "@configuration",
    query: {
      fields: ["campaigns_footer"],
    },
  });
}

export function CampaignSeo() {
  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Configuration").Configuration>} */
  const { swrResponse: configurationFooterResponse } = useApi(
    getUrlCampaignsFooter(),
  );

  return (
    <CampaignSeoBlock
      configurationFooterResponse={configurationFooterResponse}
    />
  );
}

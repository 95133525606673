import {
  Box,
  Button,
  HStack,
  IconButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { isCampaignDateButtonEnabledInSearch } from "@raiden/library-ui/helpers/campaigns/isDateButtonEnabledInSearch";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GuestsInputBody } from "@raiden/library-ui/components/Form/GuestsInput/Body";
import { Icon } from "@raiden/library-ui/components/Icon";
import { SearchRecap } from "../../../components/SearchHotels/Recap";
import { SearchDatesForm } from "../Dates/Form";
import { TabDestination } from "./TabDestination";

/**
 * @typedef {object} Props
 * @property {number} step
 * @property {import("react").Dispatch<import("react").SetStateAction<number>>} setStep
 * @property {() => void} submit
 * @property {() => void} onClose
 * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
 * @property {import("@raiden/library-ui/types/Campaign").Campaign | undefined} [campaign]
 * @property {import("@raiden/library-ui/types/Region").Region} [region]
 * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
 */
/**
 * @param {Props} props
 */
export function SearchModalContent({
  step,
  setStep,
  submit,
  onClose,
  form,
  campaign,
  region,
  department,
}) {
  const intl = useIntl();

  const [shouldDisplayShadows, setShouldDisplayShadows] = useState(false);

  const modalBodyRef = useRef(/** @type {HTMLDivElement | null} */ (null));
  const modalBodyContentRef = useRef(
    /** @type {HTMLDivElement | null} */ (null),
  );

  const updateShadowState = useCallback(() => {
    if (modalBodyRef.current) {
      // show shadows if the content is scrollable
      setShouldDisplayShadows(
        modalBodyRef.current.scrollHeight > modalBodyRef.current.clientHeight,
      );
    }
  }, []);

  useEffect(() => {
    requestAnimationFrame(updateShadowState);

    let resizeObserver = new ResizeObserver((e) => {
      updateShadowState();
    });

    if (modalBodyRef.current) {
      resizeObserver.observe(modalBodyRef.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [updateShadowState]);

  useEffect(() => {
    requestAnimationFrame(updateShadowState);

    let resizeObserver = new ResizeObserver((e) => {
      updateShadowState();
    });

    if (modalBodyContentRef.current) {
      resizeObserver.observe(modalBodyContentRef.current);
    }

    return () => {
      resizeObserver?.disconnect();
    };
  }, [updateShadowState]);

  const handleClickSearch = useCallback(
    (e) => {
      e.preventDefault();
      onClose();
      submit();
    },
    [onClose, submit],
  );

  const _steps = useMemo(() => {
    return [
      {
        id: "destination",
        isEnabled: !campaign?.localization,
        tab: (
          <Tab key="destination">
            <FormattedMessage defaultMessage="Destination" />
          </Tab>
        ),
        panel: (
          <TabPanel p="0" key="destination">
            <TabDestination
              campaign={campaign}
              form={form}
              region={region}
              department={department}
            />
          </TabPanel>
        ),
      },
      {
        id: "dates",
        isEnabled:
          campaign === undefined ||
          (campaign && isCampaignDateButtonEnabledInSearch({ campaign })),
        tab: (
          <Tab key="dates">
            <FormattedMessage defaultMessage="Dates" />
          </Tab>
        ),
        panel: (
          <TabPanel p="0" key="dates">
            <SearchDatesForm form={form} campaign={campaign ?? null} />
          </TabPanel>
        ),
      },
      {
        id: "guests",
        isEnabled: true,
        tab: (
          <Tab key="guests">
            <FormattedMessage defaultMessage="Voyageurs" />
          </Tab>
        ),
        panel: (
          <TabPanel p="0" px="1rem" key="guests">
            <GuestsInputBody
              isAdultsDisabled={
                campaign?.attributes?.find((attr) => attr.name === "adults") !==
                undefined
              }
              isChildrenDisabled={
                campaign?.attributes?.find(
                  (attr) => attr.name === "children",
                ) !== undefined
              }
              isBabiesDisabled={
                campaign?.attributes?.find((attr) => attr.name === "babies") !==
                undefined
              }
              isPetsDisabled={
                campaign?.attributes?.find((attr) => attr.name === "pets") !==
                undefined
              }
            />
          </TabPanel>
        ),
      },
    ];
  }, [campaign, department, form, region]);

  const steps = useMemo(() => {
    return _steps.filter((step) => step.isEnabled);
  }, [_steps]);

  const isNextStepPossible = useMemo(() => {
    if (step < steps.length - 1) {
      return true;
    }
    return false;
  }, [step, steps]);

  // #region return
  return (
    <>
      <ModalHeader p="1rem">
        <HStack w="full">
          <HStack flexGrow={1}>
            <Box flexGrow={1}>
              <SearchRecap form={form} campaign={campaign} />
            </Box>
          </HStack>

          <IconButton
            onClick={onClose}
            variant="outline"
            borderRadius="full"
            icon={<Icon icon="ms_close" />}
            tabIndex={0}
            aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
          />
        </HStack>
      </ModalHeader>

      <ModalBody
        ref={modalBodyRef}
        mx="1rem"
        py="0"
        px="0"
        boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
        borderRadius="32px"
        position="relative"
        overflowY="auto">
        <Box ref={modalBodyContentRef} flexGrow={1}>
          <Tabs
            index={step}
            onChange={setStep}
            isLazy={true}
            lazyBehavior="unmount"
            variant="soft-rounded"
            isFitted={true}
            colorScheme="brandPrimary">
            <TabList
              py="1rem"
              px="1rem"
              position="sticky"
              top="0"
              backgroundColor="#fff"
              zIndex={1}
              borderBottomWidth={shouldDisplayShadows ? "1px" : "0"}
              overflowX="auto">
              {steps.map((step) => step.tab)}
            </TabList>

            <TabPanels
              pt={shouldDisplayShadows ? "1rem" : "0"}
              transition="all .25s ease">
              {steps.map((step) => step.panel)}
            </TabPanels>
          </Tabs>
        </Box>
      </ModalBody>

      <ModalFooter px="1rem">
        <Wrap spacing="0.5rem">
          {isNextStepPossible && (
            <Button
              onClick={() => {
                setStep(step + 1);
              }}
              variant="outline"
              borderRadius="full">
              <Text as="span" variant="button-md" color="inherit">
                <FormattedMessage defaultMessage="Suivant" />
              </Text>
            </Button>
          )}

          <Button
            type="submit"
            onClick={handleClickSearch}
            leftIcon={<Icon icon="ms_search" />}
            colorScheme="brandSecondary"
            borderRadius="full">
            <Text as="span" variant="button-md" color="inherit">
              <FormattedMessage defaultMessage="Rechercher" />
            </Text>
          </Button>
        </Wrap>
      </ModalFooter>
    </>
  );
}

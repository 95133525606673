import { Box, Flex } from "@chakra-ui/react";
import { memo } from "react";

export const PageLayoutPublic = memo(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactElement} [header]
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props & import("@chakra-ui/react").FlexProps} props
   */
  function PageLayoutPublic({ header, children, ...otherProps }) {
    return (
      <Flex flexGrow={1} flexDirection="column" justify="flex-end">
        <Box zIndex={1}>{header}</Box>

        <Flex
          flexGrow={1}
          flexShrink={0}
          flexDir="column"
          py="2rem"
          {...otherProps}>
          {children}
        </Flex>
      </Flex>
    );
  },
);

import {
  Box,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdClose, MdExpandMore } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import { useApi } from "../../../hooks/useApi";
import AutocompleteBody from "./Body";
import { DefaultOption } from "./DefaultOption";
import { DefaultTag } from "./DefaultTag";
import { AutocompleteTags } from "./Tags";

/**
 * @typedef {string | number} AutocompleteItemValue
 */
/**
 * @typedef {AutocompleteItemValue | AutocompleteItemValue[]} AutocompleteValue
 */
/**
 * @callback AutoCompleteToggleValue
 * @param {{ value: AutocompleteItemValue, item?: {} }} params
 */
/**
 * @callback AutocompleteRemoveValue
 * @param {{ value: AutocompleteItemValue }} params
 */
/**
 * @callback AutocompleteResolveItemName
 * @param {{ item: {} }} params
 * @returns {string}
 */
/**
 * @template {AutocompleteItemValue} T
 * @typedef {(params: { item: {} }) => T} AutocompleteResolveItemValue
 */
/**
 * @callback AutocompleteResolveItemByValue
 * @param {{value: AutocompleteItemValue}} params
 * @returns {{} | undefined}
 */
/**
 * @callback AutocompleteFilterItemByValue
 * @param {{ item: {} }} params
 * @returns {{} | undefined} }
 */
/**
 * @callback AutocompleteOnChange
 * @param {AutocompleteItemValue[]} value
 */

/**
 * @typedef {object} AutocompleteRenderOptionParams
 * @property {any} ref
 * @property {AutocompleteValue} value
 * @property {object} [item]
 * @property {AutocompleteItemValue} itemValue
 * @property {string} [name]
 * @property {AutoCompleteToggleValue} toggleValue
 * @property {() => void} focusPrev
 * @property {() => void} focusNext
 */
/**
 * @callback AutocompleteRenderOption
 * @param {AutocompleteRenderOptionParams} params
 * @returns {import("react").ReactElement}
 */
/** @type {AutocompleteRenderOption} */
function defaultRenderOption({ ...props }) {
  return <DefaultOption {...props} />;
}

/**
 * @template {AutocompleteItemValue[]} TValue
 * @typedef {object} AutocompleteCommonProps
 * @property {string} name
 * @property {TValue} value
 * @property {(params: {target: {name: string, value: TValue | ""}}) => void} onChange
 * @property {() => void} [onHydrationComplete]
 * @property {boolean} [isDisabled]
 * @property {boolean} [isRequired]
 * @property {boolean} [isInvalid]
 * @property {string} [maxWidth]
 * @property {string} [maxPopoverWidth]
 */
/**
 * @typedef {object} AutocompleteRenderTagParams
 * @property {{}} [item]
 * @property {AutocompleteItemValue} itemValue
 * @property {AutocompleteResolveItemName} resolveItemName
 * @property {boolean} isHydrationDone
 * @property {AutocompleteRemoveValue} removeValue
 * @property {boolean} isMultiple
 */
/**
 * @callback AutocompleteRenderTag
 * @param {AutocompleteRenderTagParams} params
 * @returns {import("react").ReactElement}
 */
/** @type {AutocompleteRenderTag} */
function defaultRenderTag({ ...props }) {
  return <DefaultTag {...props} />;
}

const Autocomplete = forwardRef(
  /**
   * @template {AutocompleteValue} TValue
   * @typedef {object} Props
   * @property {AutocompleteResolveItemName} resolveItemName
   * @property {AutocompleteResolveItemValue<AutocompleteItemValue>} resolveItemValue
   * @property {AutocompleteFilterItemByValue} [filterItemValue]
   * @property {(params: {page: number, fields: any}) => string} generateUrl
   * @property {(params: { valuesToHydrate: any[] }) => string} generateHydrationUrl
   * @property {AutocompleteRenderOption} [renderOption]
   * @property {AutocompleteRenderTag} [renderTag]
   * @property {boolean} isMultiple
   * @property {number} [minSearchTermLength]
   * @property {boolean} [popoverMatchWidth]
   * @property {boolean} [popoverContentOnTop]
   * @property {import("react").ReactNode} [placeholder]
   */
  /**
   * @template {AutocompleteValue} TValue
   * @param {Props<TValue> & AutocompleteCommonProps<TValue>} props
   */
  function Autocomplete(props, ref) {
    const {
      name,
      value: _value,
      onChange: _onChange,
      isMultiple,
      resolveItemName,
      resolveItemValue,
      generateUrl,
      generateHydrationUrl,
      renderOption = defaultRenderOption,
      renderTag = defaultRenderTag,
      onHydrationComplete,
      maxWidth,
      maxPopoverWidth = "500px",
      minSearchTermLength = 0,
      popoverMatchWidth = false,
      popoverContentOnTop = false,
      placeholder,
      filterItemValue,
    } = props;

    const intl = useIntl();

    // internal object used to map the vaues to the corresponding items
    const [valueItemMap, setValueItemMap] = useState(
      /** @type {object} */ ({}),
    );

    const inputRef = useRef(/** @type {HTMLInputElement | null} */ (null));

    const borderColor = useColorModeValue("gray.200", "whiteAlpha.300");
    const borderColorInvalid = useColorModeValue("red.500", "red.300");
    const boxShadowInvalid = useColorModeValue(
      "0 0 0 1px var(--chakra-colors-red-500)",
      "0 0 0 1px var(--chakra-colors-red-300)",
    );

    /** @type {AutocompleteItemValue[]} */
    const values = useMemo(() => {
      let value = [];
      if (Array.isArray(_value)) {
        value = _value;
      } else {
        if (_value !== "") {
          value = [_value];
        }
      }
      return value;
    }, [_value]);

    // persistent values that persist at the top of the body when the popover is opened
    const [persistentValues, setPersistentValues] = useState(values);

    const valuesToHydrate = useMemo(() => {
      const valuesToHydrate = [];
      values.forEach((value) => {
        if (!valueItemMap[value]) {
          valuesToHydrate.push(value);
        }
      });
      return valuesToHydrate;
    }, [valueItemMap, values]);

    const onChange = useCallback(
      /** @type {AutocompleteOnChange} */
      (value) => {
        if (isMultiple) {
          _onChange({
            target: {
              name,
              // @ts-ignore ignored due to the fact that the type cannot be inferred
              value,
            },
          });
        } else {
          const normalizedValue = value[value.length - 1] ?? "";
          _onChange({
            target: {
              name,
              // @ts-ignore ignored due to the fact that the type cannot be inferred
              value: normalizedValue,
            },
          });
        }
      },
      [_onChange, isMultiple, name],
    );

    /** @type {import("../../../hooks/useApi").UseApi<any>} */
    const { swrResponse: swrResponseHydration } = useApi(
      valuesToHydrate.length > 0
        ? generateHydrationUrl({ valuesToHydrate })
        : null,
    );

    useEffect(() => {
      swrResponseHydration.data?.data.forEach((item) => {
        setValueItemMap((prev) => ({
          ...prev,
          [resolveItemValue({ item })]: item,
        }));
      });
    }, [resolveItemValue, swrResponseHydration.data?.data]);

    /** return the item corresponding to a given value */
    const resolveItemByValue = useCallback(
      /** @type {AutocompleteResolveItemByValue} */
      ({ value }) => {
        return valueItemMap?.[value] ?? undefined;
      },
      [valueItemMap],
    );

    const removeValue = useCallback(
      /** @type {AutocompleteRemoveValue} */
      ({ value }) => {
        onChange(values.filter((itemValue) => itemValue !== value));
        setPersistentValues((prev) => prev.filter((item) => item !== value));
      },
      [onChange, values],
    );

    const handleClickRemoveTags = useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onChange([]);
        setPersistentValues([]);
      },
      [onChange],
    );

    const handleOpen = useCallback(() => {
      setPersistentValues(values);
    }, [values]);

    // on hydration complete
    useEffect(() => {
      if (swrResponseHydration.isValidating === false) {
        onHydrationComplete?.();
      }
    }, [onHydrationComplete, swrResponseHydration.isValidating]);

    return (
      <>
        {values?.map?.((value) => (
          // code nécessaire pour le fonctionnement des tags du moteur de recherche
          <input
            ref={function (ref) {
              if (ref) {
                const valueIndex = values.indexOf(value);
                // @ts-ignore
                ref._newValue = [...values].filter(function (
                  _,
                  tempValueIndex,
                ) {
                  return tempValueIndex !== valueIndex;
                });
              }
            }}
            type="hidden"
            name={name}
            value={value}
            data-value={resolveItemName({
              item: resolveItemByValue({ value }) ?? value,
            })}
            key={value}
          />
        ))}

        <Box>
          <Popover
            isLazy={true}
            lazyBehavior="unmount"
            initialFocusRef={inputRef}
            placement="bottom-start"
            matchWidth={popoverMatchWidth}
            closeOnBlur={true}
            strategy="fixed"
            offset={[0, -1]}
            onOpen={handleOpen}
            modifiers={
              popoverContentOnTop
                ? [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -40],
                      },
                    },
                  ]
                : undefined
            }>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <HStack
                    ref={ref}
                    tabIndex={props.isDisabled ? -1 : 0}
                    borderWidth="1px"
                    height="40px"
                    borderRadius="6px"
                    minWidth="200px"
                    maxWidth={maxWidth}
                    position="relative"
                    cursor="pointer"
                    borderColor={
                      props.isInvalid ? borderColorInvalid : borderColor
                    }
                    boxShadow={props.isInvalid ? boxShadowInvalid : undefined}
                    pl="1rem"
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        event.preventDefault();
                        // @ts-ignore
                        event.target.click();
                      }
                    }}
                    opacity={props.isDisabled ? 0.5 : 1}
                    pointerEvents={props.isDisabled ? "none" : undefined}>
                    {values.length === 0 ? (
                      <Box flexGrow={1}>
                        {placeholder ? (
                          typeof placeholder === "string" ? (
                            <Text>{placeholder}</Text>
                          ) : (
                            placeholder
                          )
                        ) : (
                          <FormattedMessage defaultMessage="Sélectionner..." />
                        )}
                      </Box>
                    ) : (
                      <AutocompleteTags
                        renderTag={renderTag}
                        values={values}
                        resolveItemByValue={resolveItemByValue}
                        resolveItemName={resolveItemName}
                        isHydrationDone={!swrResponseHydration.isValidating}
                        removeValue={removeValue}
                        isMultiple={isMultiple}
                      />
                    )}

                    <Box flexShrink={0}>
                      {values.length > 0 && (
                        <IconButton
                          onClick={handleClickRemoveTags}
                          tabIndex={props.isDisabled ? -1 : 0}
                          variant="ghost"
                          colorScheme="darkGray"
                          icon={<Icon as={MdClose} />}
                          aria-label={intl.formatMessage({
                            defaultMessage: "Tout supprimer",
                          })}
                        />
                      )}

                      <IconButton
                        tabIndex={props.isDisabled ? -1 : 0}
                        variant="ghost"
                        colorScheme="darkGray"
                        icon={<Icon as={MdExpandMore} />}
                        aria-label={intl.formatMessage({
                          defaultMessage: "Basculer",
                        })}
                      />
                    </Box>
                  </HStack>
                </PopoverTrigger>

                <ConditionalWrapper
                  hasWrapper={false}
                  wrapper={(children) => <Portal>{children}</Portal>}>
                  <PopoverContent
                    width="inherit"
                    overflow="hidden"
                    maxHeight="40vh"
                    onKeyDown={(event) => {
                      if (event.key === "Escape") {
                        event.stopPropagation();
                      }
                    }}
                    maxWidth={`min(${maxPopoverWidth},100vw)`}>
                    <AutocompleteBody
                      value={_value}
                      onChange={onChange}
                      values={values}
                      renderOption={renderOption}
                      resolveItemName={resolveItemName}
                      resolveItemValue={resolveItemValue}
                      inputRef={inputRef}
                      generateUrl={generateUrl}
                      setValueItemMap={setValueItemMap}
                      onClose={onClose}
                      resolveItemByValue={resolveItemByValue}
                      removeValue={removeValue}
                      persistentValues={persistentValues}
                      setPersistentValues={setPersistentValues}
                      isMultiple={isMultiple}
                      minSearchTermLength={minSearchTermLength}
                      filterItemValue={filterItemValue}
                    />
                  </PopoverContent>
                </ConditionalWrapper>
              </>
            )}
          </Popover>
        </Box>
      </>
    );
  },
);

export default Autocomplete;

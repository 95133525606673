import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import useDebounce from "@raiden/library-ui/hooks/useDebounce";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
/**
 * @callback PlacesSearchSetRegionCode
 * @param {string} code
 */
/**
 * @callback PlacesSearchSetDepartmentCode
 * @param {string} code
 */
/**
 * @callback PlacesSearchSetLegacyRegionCode
 * @param {string} code
 */
/**
 * @callback PlacesSearchGetPlaceCallback
 * @param {{ placeId: string}} params
 */

/**
 * @typedef {object} PlacesSearchMap
 * @property {number} latitude
 * @property {number} longitude
 * @property {number} radius
 */

/**
 * @typedef {object} PlacesSearchValue
 * @property {import("@raiden/library-ui/types/Place").Place | ""} place
 * @property {string} region_code
 * @property {string} department_code
 * @property {string} legacy_region_code
 * @property {PlacesSearchMap | ""} map
 */

/**
 * @typedef {object} RenderParams
 * @property {string} term
 * @property {string} debouncedTerm
 * @property {import("react").Dispatch<import("react").SetStateAction<string>>} setTerm
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/PlaceAutocomplete.js").PlaceAutocomplete[]>>} swrResponse
 * @property {PlacesSearchGetPlaceCallback} getPlace
 * @property {import("@raiden/library-ui/types/Place.js").Place | null} place
 * @property {string} region_code
 * @property {string} department_code
 * @property {string} legacy_region_code
 * @property {PlacesSearchSetRegionCode} setRegionCode
 * @property {PlacesSearchSetLegacyRegionCode} setLegacyRegionCode
 * @property {PlacesSearchSetDepartmentCode} setDepartmentCode
 * @property {import("react").ForwardedRef<HTMLInputElement>} ref
 */

export const PlacesSearch = forwardRef(
  /**
   * @typedef {object} Props
   * @property {PlacesSearchValue} value
   * @property {(params: { target: { value: PlacesSearchValue}}) => void} onChange
   * @property {() => void} [onSuccess]
   * @property {() => void} [onChangeTerm]
   * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
   * @property {string} [label]
   * @property {(params: RenderParams) => import("react").ReactElement} children
   */
  /**
   * @param {Props} props
   */
  function PlacesSearch(
    { value, onChange, onSuccess, onChangeTerm, label, children },
    ref,
  ) {
    const intl = useIntl();

    const [term, _setTerm] = useState("");

    const debouncedTerm = useDebounce(term, { delay: 250, mount: true });

    const place = typeof value?.place === "string" ? null : value?.place;

    useEffect(() => {
      if (label) {
        _setTerm("");
      }
    }, [label]);

    const setPlace = useCallback(
      /**
       * @param {import("@raiden/library-ui/types/Place.js").Place | null} place
       */
      (place) => {
        onChange({
          target: {
            value: {
              place: place ?? "",
              region_code: "",
              department_code: "",
              legacy_region_code: "",
              map: "",
            },
          },
        });
        if (place) {
          onSuccess?.();
        }
      },
      [onChange, onSuccess],
    );

    const setRegionCode = useCallback(
      /**
       * @type {PlacesSearchSetRegionCode}
       */
      (code) => {
        onChange({
          target: {
            value: {
              place: "",
              region_code: code,
              department_code: "",
              legacy_region_code: "",
              map: "",
            },
          },
        });
        onSuccess?.();
      },
      [onChange, onSuccess],
    );
    const setLegacyRegionCode = useCallback(
      /**
       * @type {PlacesSearchSetLegacyRegionCode}
       */
      (code) => {
        onChange({
          target: {
            value: {
              place: "",
              region_code: "",
              department_code: "",
              map: "",
              legacy_region_code: code,
            },
          },
        });
        onSuccess?.();
      },
      [onChange, onSuccess],
    );

    const setDepartmentCode = useCallback(
      /**
       * @type {PlacesSearchSetDepartmentCode}
       */
      (code) => {
        onChange({
          target: {
            value: {
              place: "",
              region_code: "",
              department_code: code,
              legacy_region_code: "",
              map: "",
            },
          },
        });
        onSuccess?.();
      },
      [onChange, onSuccess],
    );

    /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/PlaceAutocomplete.js").PlaceAutocomplete[]>} */
    const { swrResponse } = useApi(
      debouncedTerm.length >= 2
        ? generateApiUrl({
            id: "@places.autocomplete",
            query: {
              term: debouncedTerm,
            },
          })
        : null,
      {
        swrConfig: {
          keepPreviousData: true,
        },
      },
    );

    const { request, toastError } = useRequest();

    const setTerm = useCallback(
      /** @param {string} newValue */
      (newValue) => {
        // only reset the place once if it was set
        if (
          value.place !== "" ||
          value.region_code !== "" ||
          value.department_code !== "" ||
          value.legacy_region_code !== ""
        ) {
          setPlace(null);
        }
        _setTerm(newValue);
        onChangeTerm?.();
      },
      [
        onChangeTerm,
        setPlace,
        value.department_code,
        value.place,
        value.region_code,
        value.legacy_region_code,
      ],
    );

    const getPlace = useCallback(
      /**
       * @type {PlacesSearchGetPlaceCallback}
       */
      ({ placeId }) => {
        request(
          generateApiUrl({
            id: "@places.show",
            parameters: { placeId },
          }),
        )
          .then((response) => {
            const newPlace = response.data ?? null;
            setPlace(newPlace);
          })
          .catch((error) => {
            const message = apiGetErrorDetail({ error });
            toastError({
              title: intl.formatMessage({
                defaultMessage: "Une erreur est survenue",
              }),
              description: message,
            });
          });
      },
      [intl, request, setPlace, toastError],
    );

    return children({
      term: label ?? term,
      debouncedTerm,
      setTerm,
      swrResponse,
      getPlace,
      place,
      region_code: value?.region_code,
      department_code: value?.department_code,
      legacy_region_code: value?.legacy_region_code,
      setRegionCode,
      setDepartmentCode,
      setLegacyRegionCode,
      ref,
    });
  },
);

import { Box, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import { PlacesSearchMap } from "./Map";
import { PlacesSearchResults } from "./Results";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Place").Place | null} place
 * @property {string} term
 * @property {string} debouncedTerm
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/PlaceAutocomplete.js").PlaceAutocomplete[]>>} swrResponse
 * @property {import(".").PlacesSearchGetPlaceCallback} getPlace
 * @property {string} region_code
 * @property {string} department_code
 * @property {string} legacy_region_code
 * @property {import(".").PlacesSearchSetRegionCode} setRegionCode
 * @property {import(".").PlacesSearchSetDepartmentCode} setDepartmentCode
 * @property {import(".").PlacesSearchSetLegacyRegionCode} setLegacyRegionCode
 */
/**
 * @param {Props} props
 */
export function PlacesPopoverBody({
  place,
  term,
  debouncedTerm,
  swrResponse,
  getPlace,
  region_code,
  department_code,
  legacy_region_code,
  setRegionCode,
  setDepartmentCode,
  setLegacyRegionCode,
}) {
  return (
    <Box position="relative" p="1.25rem" overflow="hidden" w="full">
      {term.length >= 2 &&
      debouncedTerm.length >= 2 &&
      region_code === "" &&
      department_code === "" &&
      legacy_region_code === "" ? (
        <DataHandler
          swrResponse={swrResponse}
          noResult={
            <Text color="gray.500">
              <FormattedMessage defaultMessage="Aucun résultat" />
            </Text>
          }>
          {({ data: placesAutocomplete }) => (
            <PlacesSearchResults
              place={place}
              term={term}
              placesAutocomplete={placesAutocomplete}
              getPlace={getPlace}
              setRegionCode={setRegionCode}
              setDepartmentCode={setDepartmentCode}
              setLegacyRegionCode={setLegacyRegionCode}
            />
          )}
        </DataHandler>
      ) : (
        <>
          <Text variant="h5">
            <FormattedMessage defaultMessage="Pas d'idée précise ?" />
          </Text>

          <Text variant="text-xs" color="gray.500" mt="2px">
            <FormattedMessage defaultMessage="Choisissez une région" />
          </Text>

          <Box mt="0.75rem">
            <PlacesSearchMap
              setRegionCode={setRegionCode}
              setDepartmentCode={setDepartmentCode}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

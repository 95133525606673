import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { GuestsInputBody } from "./Body";

/**
 * @typedef {(params: { label: string }) => JSX.Element} GuestsInputRenderTriggerCallback
 */

export const GuestsInput = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} [isAdultsDisabled]
   * @property {boolean} [isChildrenDisabled]
   * @property {boolean} [isBabiesDisabled]
   * @property {boolean} [isPetsDisabled]
   * @property {boolean} [isPetNacAllowed]
   * @property {boolean} [isDangerousPetsAllowed]
   * @property {number} [maxDogWeight]
   * @property {number} [maxCapacity]
   * @property {number} [maxNumberOfPets]
   * @property {import("react").MutableRefObject<any>} [containerRef]
   * @property {boolean} isOpen
   * @property {() => void} onOpen
   * @property {() => void} onClose
   * @property {GuestsInputRenderTriggerCallback} renderTrigger
   */
  /**
   * @param {Props} props
   */
  function GuestsInput({
    isAdultsDisabled = false,
    isChildrenDisabled = false,
    isBabiesDisabled = false,
    isPetsDisabled = false,
    isDangerousPetsAllowed = false,
    isPetNacAllowed = false,
    maxDogWeight,
    maxNumberOfPets,
    maxCapacity,
    containerRef,
    isOpen,
    onOpen,
    onClose,
    renderTrigger,
  }) {
    const intl = useIntl();

    const adults = useWatch({ name: "adults" });
    const children = useWatch({ name: "children" });

    const label = useMemo(() => {
      return intl.formatMessage(
        {
          defaultMessage:
            "{count, plural, =0 {Voyageur} one {# voyageur} other {# voyageurs}}",
        },
        { count: adults + children },
      );
    }, [adults, children, intl]);

    return (
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        isLazy={true}
        strategy="fixed">
        <PopoverTrigger>{renderTrigger({ label })}</PopoverTrigger>

        <Portal containerRef={containerRef}>
          <PopoverContent w="auto" borderRadius="10px">
            <PopoverBody p="1.25rem">
              <PopoverArrow />

              <GuestsInputBody
                isAdultsDisabled={isAdultsDisabled}
                isChildrenDisabled={isChildrenDisabled}
                isBabiesDisabled={isBabiesDisabled}
                isPetsDisabled={isPetsDisabled}
                maxCapacity={maxCapacity}
                maxDogWeight={maxDogWeight}
                isDangerousPetsAllowed={isDangerousPetsAllowed}
                isPetNacAllowed={isPetNacAllowed}
                maxNumberOfPets={maxNumberOfPets}
              />
            </PopoverBody>
            <PopoverFooter
              borderWidth="0px"
              display="flex"
              justifyContent="flex-end">
              <Button
                variant="ghost"
                fontWeight="500"
                onClick={onClose}
                textDecoration="underline">
                {intl.formatMessage({ defaultMessage: "Fermer" })}
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      </Popover>
    );
  },
);

import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  mergeProps,
  PopoverInput,
} from "@raiden/library-ui/components/Form/PopoverInput";
import { Icon } from "@raiden/library-ui/components/Icon";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { isCampaignDateButtonEnabledInSearch } from "@raiden/library-ui/helpers/campaigns/isDateButtonEnabledInSearch";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { memo, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GuestsInput } from "../../../../library-ui/components/Form/GuestsInput";
import { FiltersBadgePublic } from "../../../components/Form/FiltersBadgePublic";
import { SearchRecap } from "../../../components/SearchHotels/Recap";
import { DatesRecap } from "../../../components/SearchHotels/Recap/DatesRecap";
import { getSearchPlaceLabel } from "../../../helpers/search/getSearchPlaceLabel";
import { PlacesSearch } from "../../Places/Search";
import { PlacesPopoverBody } from "../../Places/Search/PopoverBody";
import { SearchDatesForm } from "../Dates/Form";
import {
  SearchFiltersModal,
  SEARCH_FILTERS_NAMES,
} from "../FiltersModal/Index";
import { SearchModal } from "../Modal";
import { SortSelect } from "./SortSelect";

const POPOVER_CONTENT_PROPS = {
  borderRadius: "10px",
  w: "446px",
  maxW: "100vw",
};

// #region compoent
export const SearchForm = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Region").Region} [region]
   * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
   * @property {boolean} withFilters
   * @property {boolean} withSort
   * @property {boolean} withDetailledSubmitButton
   * @property {() => void} submit
   * @property {string[]} appliedFilters
   * @property {boolean} [isLoading]
   * @property {import("react").Dispatch<import("react").SetStateAction<boolean>>} [setAutoSubmit]
   * @property {import("./Handler").SearchFormValues} [submittedFields]
   * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
   * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
   * @property {import("react").MutableRefObject<any>} [containerRef]
   * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaOtaTypes} [metaOtaTypes]
   * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaAmenities} [metaAmenities]
   * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaLeisureHome} [metaLeisureHome]
   * @property {import("react").Dispatch<import("react").SetStateAction<boolean>>} [setShouldFocusMarkers]
   * @property {import("@raiden/library-ui/types/Configuration").ConfigurationSearchableAmenities[]} [searchableAmenities]
   */
  /**
   * @param {Props} props
   */
  function SearchForm({
    region,
    department,
    withFilters,
    withSort,
    withDetailledSubmitButton,
    submit,
    appliedFilters,
    isLoading = false,
    setAutoSubmit,
    submittedFields,
    form,
    campaign,
    containerRef,
    metaOtaTypes,
    metaAmenities,
    metaLeisureHome,
    setShouldFocusMarkers,
    searchableAmenities,
  }) {
    const intl = useIntl();

    const isMobile = useBreakpointValue({ base: true, md: false });

    const { configuration } = useConfiguration();

    const {
      isOpen: isOpenSearchModal,
      onOpen: onOpenSearchModal,
      onClose: onCloseSearchModal,
    } = useDisclosure();

    const {
      isOpen: isOpenSettingsModal,
      onOpen: onOpenSettingsModal,
      onClose: onCloseSettingsModal,
    } = useDisclosure();

    const {
      isOpen: isOpenPlacesPopover,
      onOpen: onOpenPlacesPopover,
      onClose: onClosePlacesPopover,
    } = useDisclosure();

    const {
      isOpen: isOpenGuestsInput,
      onOpen: onOpenGuestsInput,
      onClose: onCloseGuestsInput,
    } = useDisclosure();

    const {
      isOpen: isOpenDatesPopover,
      onOpen: onOpenDatesPopover,
      onClose: onCloseDatesPopover,
    } = useDisclosure();

    useEffect(() => {
      if (isMobile) {
        onCloseDatesPopover();
      }
    }, [isMobile, onCloseDatesPopover]);

    const handleChangeTerm = useCallback(() => {
      onOpenPlacesPopover();
      const mapLocation = form.getValues("location.map");
      if (mapLocation !== "") {
        form.setValue("location", {
          map: "",
          place: "",
          region_code: "",
          department_code: "",
          legacy_region_code: "",
        });
      }
    }, [form, onOpenPlacesPopover]);

    const handleClickInput = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLInputElement>} */
      (event) => {
        event.target["focus"]?.();
      },
      [],
    );

    useEffect(() => {
      // disable auto submit when drawer or settings modal is open
      setAutoSubmit?.(
        isOpenSettingsModal === false &&
          isOpenSearchModal === false &&
          isOpenGuestsInput === false &&
          isOpenDatesPopover === false,
      );
    }, [
      isOpenSearchModal,
      isOpenGuestsInput,
      isOpenSettingsModal,
      setAutoSubmit,
      isOpenDatesPopover,
    ]);

    /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/AmenityDictionaryItem").AmenityDictionaryItem[]>} */
    const { swrResponse: swrResponseAmenities } = useApi(
      withFilters
        ? generateApiUrl({
            id: "@amenities.dictionary",
            query: {
              per_page: 250,
            },
          })
        : null,
    );

    const resolvers = useMemo(() => {
      return {
        amenity_ids: () => {
          let count = 0;

          // We don't want to count amenities from the highlight picker
          const amenitiesWithoutHighlightedAmenities =
            submittedFields?.amenity_ids.filter(
              (amenityId) =>
                !searchableAmenities?.find(
                  (amenity) => amenityId === amenity.id,
                ),
            ) ?? [];

          amenitiesWithoutHighlightedAmenities.forEach((amenityId) => {
            if (
              !campaign?.attributes
                ?.find((attr) => attr.name === "amenity_ids")
                ?.value?.map((strId) => parseInt(strId, 10))
                ?.includes(amenityId)
            ) {
              count++;
            }
          });
          return count;
        },
      };
    }, [
      campaign?.attributes,
      searchableAmenities,
      submittedFields?.amenity_ids,
    ]);

    const isInputPlaceDisabled =
      region !== undefined ||
      department !== undefined ||
      (campaign?.localization ?? undefined) !== undefined;

    const renderTrigger = useCallback(
      /** @type {import("@raiden/library-ui/components/Form/GuestsInput").GuestsInputRenderTriggerCallback} */
      ({ label }) => (
        <Button
          variant="outline"
          leftIcon={<Icon icon="ms_people" size="24px" color="gray.500" />}
          minW="9.3125rem"
          h="44px"
          justifyContent="flex-start">
          <Text as="span" variant="text-input">
            {label}
          </Text>
        </Button>
      ),
      [],
    );

    const handleSuccess = useCallback(() => {
      setShouldFocusMarkers?.(true);
      onClosePlacesPopover();
    }, [onClosePlacesPopover, setShouldFocusMarkers]);

    // #region return
    return (
      <>
        <Box display={{ base: "none", md: "block" }} position="relative">
          <HStack
            w="content"
            backgroundColor="#fff"
            borderRadius="2.25rem"
            boxShadow="rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"
            px="1.875rem"
            py="1rem">
            <Box flexGrow={1}>
              <FormControlRHF
                name="location"
                control={form.control}
                render={(field) => (
                  <PlacesSearch
                    {...field}
                    campaign={campaign}
                    onSuccess={handleSuccess}
                    onChangeTerm={handleChangeTerm}
                    label={getSearchPlaceLabel({
                      intl,
                      configuration,
                      value: field.value,
                      campaign,
                    })}>
                    {({
                      term,
                      debouncedTerm,
                      setTerm,
                      swrResponse,
                      getPlace,
                      place,
                      region_code,
                      department_code,
                      legacy_region_code,
                      setRegionCode,
                      setDepartmentCode,
                      setLegacyRegionCode,
                      ref,
                    }) => (
                      <PopoverInput
                        isOpen={isOpenPlacesPopover}
                        onOpen={onOpenPlacesPopover}
                        onClose={onClosePlacesPopover}
                        renderInput={({ inputProps }) => (
                          <InputGroup alignItems="center">
                            <InputLeftElement
                              zIndex="initial"
                              opacity={isInputPlaceDisabled ? 0.5 : undefined}
                              top="initial">
                              <Icon
                                icon="ms_location_on"
                                size="24px"
                                color="gray.500"
                              />
                            </InputLeftElement>

                            <Input
                              ref={ref}
                              type="search"
                              placeholder={intl.formatMessage({
                                defaultMessage: "Votre destination",
                              })}
                              textOverflow="ellipsis"
                              w="100%"
                              h="44px"
                              isDisabled={isInputPlaceDisabled}
                              {...mergeProps(
                                {
                                  width: "300px",
                                  value: term,
                                  onChange: (e) => {
                                    setTerm(e.target["value"]);
                                  },
                                  onClick: handleClickInput,
                                },
                                inputProps,
                              )}
                              onKeyDown={(e) => {
                                if (e.key === "Escape") {
                                  if (term !== "") {
                                    e.stopPropagation();
                                    setTerm("");
                                  }
                                }
                                if (
                                  e.key === "Enter" &&
                                  !swrResponse.isValidating
                                ) {
                                  const firstResult = document.querySelector(
                                    "#places-search-result-0",
                                  );
                                  firstResult?.["click"]?.();
                                }
                              }}
                            />

                            <InputRightElement
                              zIndex="initial"
                              opacity={
                                Boolean(place) ||
                                Boolean(region_code) ||
                                Boolean(department_code) ||
                                Boolean(campaign?.localization) ||
                                Boolean(legacy_region_code) ||
                                field.value.map !== ""
                                  ? isInputPlaceDisabled
                                    ? 0.5
                                    : 1
                                  : 0
                              }
                              top="initial"
                              transform={`scale(${
                                Boolean(place) ||
                                Boolean(region_code) ||
                                Boolean(department_code) ||
                                Boolean(legacy_region_code) ||
                                Boolean(campaign?.localization) ||
                                field.value.map !== ""
                                  ? 1
                                  : 0
                              })`}
                              transition="all .25s ease">
                              <Icon
                                icon="ms_check"
                                size="1.5rem"
                                color="green.500"
                              />
                            </InputRightElement>
                          </InputGroup>
                        )}
                        popoverBody={
                          <PlacesPopoverBody
                            place={place}
                            term={term}
                            swrResponse={swrResponse}
                            getPlace={getPlace}
                            region_code={region_code}
                            department_code={department_code}
                            legacy_region_code={legacy_region_code}
                            setRegionCode={setRegionCode}
                            setDepartmentCode={setDepartmentCode}
                            setLegacyRegionCode={setLegacyRegionCode}
                            debouncedTerm={debouncedTerm}
                          />
                        }
                        popoverContentProps={POPOVER_CONTENT_PROPS}
                      />
                    )}
                  </PlacesSearch>
                )}
              />
            </Box>

            <Box>
              <Popover
                isOpen={isOpenDatesPopover}
                onOpen={onOpenDatesPopover}
                onClose={onCloseDatesPopover}
                isLazy={true}
                lazyBehavior="unmount"
                placement="bottom"
                strategy="fixed">
                <PopoverTrigger>
                  <Button
                    isDisabled={
                      campaign &&
                      !isCampaignDateButtonEnabledInSearch({
                        campaign,
                      })
                    }
                    leftIcon={
                      <Icon
                        icon="ms_calendar_month"
                        size="24px"
                        color="gray.500"
                      />
                    }
                    variant="outline"
                    minW="13.6875rem"
                    justifyContent="flex-start"
                    h="44px">
                    <Text variant="button-md">
                      <DatesRecap
                        form={form}
                        fallback={intl.formatMessage({
                          defaultMessage: "Arrivée - départ",
                        })}
                      />
                    </Text>
                  </Button>
                </PopoverTrigger>

                <PopoverContent width="min(802px, 100vw)">
                  <PopoverArrow />

                  <PopoverBody p="20px">
                    <SearchDatesForm form={form} campaign={campaign ?? null} />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>

            <GuestsInput
              isAdultsDisabled={
                campaign?.attributes?.find((attr) => attr.name === "adults") !==
                undefined
              }
              isChildrenDisabled={
                campaign?.attributes?.find(
                  (attr) => attr.name === "children",
                ) !== undefined
              }
              isBabiesDisabled={
                campaign?.attributes?.find((attr) => attr.name === "babies") !==
                undefined
              }
              isPetsDisabled={
                campaign?.attributes?.find((attr) => attr.name === "pets") !==
                undefined
              }
              containerRef={containerRef}
              isOpen={isOpenGuestsInput}
              onOpen={onOpenGuestsInput}
              onClose={onCloseGuestsInput}
              renderTrigger={renderTrigger}
            />

            {withFilters && (
              <FiltersBadgePublic
                appliedFilters={appliedFilters}
                filtersName={SEARCH_FILTERS_NAMES}
                resolvers={resolvers}>
                <Button
                  onClick={onOpenSettingsModal}
                  leftIcon={
                    <Icon icon="ms_tune" size="24px" color="gray.500" />
                  }
                  variant="outline"
                  h="44px">
                  <Text as="span" variant="text-input">
                    <FormattedMessage defaultMessage="Filtrer" />
                  </Text>
                </Button>
              </FiltersBadgePublic>
            )}

            {withSort && (
              <FormControlRHF
                control={form.control}
                name="sort"
                render={(field) => <SortSelect field={field} />}
              />
            )}

            {withDetailledSubmitButton ? (
              <Box flexShrink={0}>
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    submit();
                  }}
                  isLoading={isLoading}
                  colorScheme="brandSecondary"
                  leftIcon={<Icon icon="ms_search" />}
                  h="44px">
                  <Text as="span" variant="button-md" color="inherit">
                    <FormattedMessage defaultMessage="Rechercher" />
                  </Text>
                </Button>
              </Box>
            ) : (
              <Box flexShrink={0}>
                <IconButton
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    submit();
                  }}
                  isLoading={isLoading}
                  colorScheme="brandSecondary"
                  icon={<Icon icon="ms_search" />}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Rechercher",
                  })}
                  h="44px"
                  w="44px"
                />
              </Box>
            )}
          </HStack>
        </Box>

        <HStack spacing="1rem" display={{ base: "flex", md: "none" }}>
          <Box
            flexGrow={1}
            boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            borderRadius="full"
            borderWidth="1px">
            <Button
              display="flex"
              gap="1rem"
              onClick={onOpenSearchModal}
              w="full"
              h="auto"
              backgroundColor="#fff"
              borderRadius="full"
              justifyContent="flex-start"
              py="0.375rem"
              textAlign="left">
              <Box minWidth="24px">
                <Icon icon="ms_search" size="24px" />
              </Box>

              <SearchRecap form={form} campaign={campaign} />
            </Button>
          </Box>

          {withFilters && (
            <Box
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              borderRadius="full">
              <FiltersBadgePublic
                appliedFilters={appliedFilters}
                filtersName={SEARCH_FILTERS_NAMES}
                resolvers={resolvers}>
                <IconButton
                  onClick={onOpenSettingsModal}
                  size="lg"
                  icon={<Icon icon="ms_tune" color="gray.500" />}
                  aria-label={intl.formatMessage({ defaultMessage: "Filtrer" })}
                  variant="outline"
                  borderRadius="full"
                  backgroundColor="#fff"
                />
              </FiltersBadgePublic>
            </Box>
          )}

          {withSort && (
            <Box
              boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
              borderRadius="full">
              <FormControlRHF
                control={form.control}
                name="sort"
                render={(field) => <SortSelect field={field} />}
              />
            </Box>
          )}
        </HStack>

        <SearchModal
          isOpen={isOpenSearchModal}
          onClose={onCloseSearchModal}
          submit={submit}
          form={form}
          campaign={campaign}
          region={region}
          department={department}
        />

        {withFilters && (
          <SearchFiltersModal
            form={form}
            metaOtaTypes={metaOtaTypes}
            metaAmenities={metaAmenities}
            metaLeisureHome={metaLeisureHome}
            isOpen={isOpenSettingsModal}
            onClose={onCloseSettingsModal}
            swrResponseAmenities={swrResponseAmenities}
            campaign={campaign}
            region={region}
            department={department}
          />
        )}
      </>
    );
  },
);

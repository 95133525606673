import {
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@chakra-ui/react";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { GuestInput } from "../GuestInput";
import FormControlRHF from "../../ReactHookForm/FormControlRHF";
import FormObserver from "../../ReactHookForm/FormObserver";
import { Icon } from "../../Icon";

export const GuestsInputBody = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} [isAdultsDisabled]
   * @property {boolean} [isChildrenDisabled]
   * @property {boolean} [isBabiesDisabled]
   * @property {boolean} [isPetsDisabled]
   * @property {boolean} [isPetNacAllowed]
   * @property {boolean} [isDangerousPetsAllowed]
   * @property {number} [maxDogWeight]
   * @property {number} [maxCapacity]
   * @property {number} [maxNumberOfPets]
   */
  /**
   * @param {Props} props
   */
  function GuestsInputBody({
    isAdultsDisabled = false,
    isChildrenDisabled = false,
    isBabiesDisabled = false,
    isPetsDisabled = false,
    isPetNacAllowed = false,
    isDangerousPetsAllowed = false,
    maxDogWeight,
    maxCapacity,
    maxNumberOfPets,
  }) {
    const intl = useIntl();

    const petHelperText = useMemo(() => {
      let informationAboutDog = undefined;

      const maximumDogWeight = maxDogWeight ?? 0;

      if (!isPetsDisabled) {
        const weight = intl.formatNumber(maximumDogWeight, {
          style: "unit",
          unit: "kilogram",
        });
        if (maximumDogWeight > 0 && isDangerousPetsAllowed) {
          informationAboutDog = intl.formatMessage(
            {
              defaultMessage: "(max {weight}, catégorie 1 et 2 autorisés)",
            },
            {
              weight,
            },
          );
        } else if (maximumDogWeight > 0) {
          informationAboutDog = intl.formatMessage(
            {
              defaultMessage: "(max {weight}, sauf catégorie 1 et 2)",
            },
            {
              weight,
            },
          );
        } else if (isDangerousPetsAllowed) {
          informationAboutDog = intl.formatMessage({
            defaultMessage: "(catégorie 1 et 2 autorisés)",
          });
        }

        if (isPetNacAllowed) {
          return intl.formatMessage(
            {
              defaultMessage:
                "Les chiens {shouldRenderDogsInformations, select, true { {informationAboutDog} } other {(sauf catégorie 1 et 2)}} et les chats sont autorisés ainsi que les nouveaux animaux de compagnie (NAC).",
            },
            {
              informationAboutDog,
              shouldRenderDogsInformations: Boolean(informationAboutDog),
            },
          );
        }
        return intl.formatMessage(
          {
            defaultMessage:
              "Seul les chiens {shouldRenderDogsInformations, select, true { {informationAboutDog} } other {(sauf catégorie 1 et 2)}} et les chats sont autorisés.",
          },
          {
            informationAboutDog,
            shouldRenderDogsInformations: Boolean(informationAboutDog),
          },
        );
      }
      return intl.formatMessage({
        defaultMessage: "Les animaux ne sont pas autorisés.",
      });
    }, [
      intl,
      isDangerousPetsAllowed,
      isPetNacAllowed,
      isPetsDisabled,
      maxDogWeight,
    ]);

    return (
      <Stack spacing="1rem">
        <FormObserver name="children">
          {(children) => (
            <FormControlRHF
              name="adults"
              isDisabled={isAdultsDisabled}
              render={(field) => (
                <GuestInput
                  title={
                    <Text variant="button-md">
                      {intl.formatMessage({ defaultMessage: "Adultes" })}
                    </Text>
                  }
                  description={intl.formatMessage({
                    defaultMessage: "(+ de 18 ans)",
                  })}
                  icon="sf_adult"
                  min={1}
                  max={
                    maxCapacity !== undefined
                      ? maxCapacity - children
                      : undefined
                  }
                  {...field}
                />
              )}
            />
          )}
        </FormObserver>

        <FormObserver name="adults">
          {(adults) => (
            <FormControlRHF
              name="children"
              isDisabled={isChildrenDisabled}
              render={(field) => (
                <GuestInput
                  title={
                    <Text variant="button-md">
                      {intl.formatMessage({ defaultMessage: "Enfants" })}
                    </Text>
                  }
                  description={intl.formatMessage({
                    defaultMessage: "(2 à 18 ans)",
                  })}
                  icon="sf_child"
                  max={
                    maxCapacity !== undefined ? maxCapacity - adults : undefined
                  }
                  {...field}
                />
              )}
            />
          )}
        </FormObserver>

        <FormControlRHF
          name="babies"
          isDisabled={isBabiesDisabled}
          render={(field) => (
            <GuestInput
              title={
                <Text variant="button-md">
                  {intl.formatMessage({ defaultMessage: "Bébés" })}
                </Text>
              }
              description={intl.formatMessage({
                defaultMessage: "(- de 2 ans)",
              })}
              icon="sf_baby"
              max={20}
              {...field}
            />
          )}
        />

        <FormControlRHF
          name="pets"
          isDisabled={isPetsDisabled}
          helperText={petHelperText}
          render={(field) => (
            <GuestInput
              title={
                <HStack spacing="0" alignItems="center">
                  <Text variant="button-md">
                    {intl.formatMessage({ defaultMessage: "Animaux" })}
                  </Text>

                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        aria-label={intl.formatMessage({
                          defaultMessage: "Animaux",
                        })}
                        maxHeight="16px"
                        display="flex"
                        justifyContent="center"
                        variant="unstyled"
                        marginInlineStart={0}
                        icon={<Icon size="16px" icon="ms_info" />}
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>{petHelperText}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                </HStack>
              }
              helperText={
                field.value > 0 ? (
                  <Text variant="text-xs" color="red.500" maxWidth="12rem">
                    {intl.formatMessage({
                      defaultMessage:
                        "Un supplément pourra vous être demandé au moment du paiement",
                    })}
                  </Text>
                ) : undefined
              }
              icon="sf_pet"
              max={maxNumberOfPets}
              {...field}
            />
          )}
        />
      </Stack>
    );
  },
);

import { Image, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { FormattedMessage } from "react-intl";
import { ErrorSearch } from "../ErrorSearch";

export function Splash404Public() {
  return (
    <Stack spacing="1.5rem" textAlign="center">
      <Stack spacing="1rem">
        <Image
          src={generateFrontPath({
            id: "@front.internal-assets",
            parameters: { filePath: "illustrations/not-found-public.svg" },
            includeBasePath: true,
          })}
          w="320px"
          maxWidth="100%"
          margin="auto"
          alt=""
        />

        <Text variant="h3">
          <FormattedMessage defaultMessage="Nous sommes désolés, nous ne trouvons pas la page que vous recherchez" />
        </Text>

        <Text variant="h5">
          <FormattedMessage
            defaultMessage="Vous pouvez vérifier l'adresse de la page ou <link>retourner à la page d'accueil en cliquant ici.</link>"
            values={{
              link: (chunks) => (
                <a
                  href={generateFrontUrl({ id: "@front.home" })}
                  style={{ textDecoration: "underline" }}>
                  {chunks}
                </a>
              ),
            }}
          />
        </Text>
      </Stack>

      <Stack pt="1.5rem">
        <ErrorSearch />
      </Stack>
    </Stack>
  );
}

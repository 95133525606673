/**
 * @param {object} params
 * @param {import("../../../types/Campaign").Campaign | import("../../../types/Configuration").ConfigurationCampaignsFooterItem} params.campaign
 * @param {string} [params.locale]
 * @param {string} [params.defaultLocale]
 * @returns {string}
 */
export function getCampaignSlug({ campaign, locale, defaultLocale }) {
  return `${campaign.id}-${
    (locale ? campaign?.slug?.[locale] : undefined) ??
    (defaultLocale ? campaign?.slug?.[defaultLocale] : undefined) ??
    ""
  }`;
}

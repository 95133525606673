import {
  Box,
  Collapse,
  Flex,
  IconButton,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import { getCampaignSlug } from "@raiden/library-ui/helpers/campaigns/getSlug";
import useLocale from "@raiden/library-ui/hooks/useLocale";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { Href } from "../../Href";
/**
 * @typedef {object} Props
 * @property {string} title
 * @property {import("@raiden/library-ui/types/Configuration").ConfigurationCampaignsFooterItem[]} items
 */
/**
 * @param {Props} props
 */
export function CampaignCollapsibleSection({ title, items }) {
  const { isOpen, onToggle } = useDisclosure();

  const translate = useTranslate();

  const { locale, defaultLocale } = useLocale();

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        onClick={onToggle}
        cursor="pointer"
        borderBottom="1px solid"
        borderBottomColor="gray.200">
        <Text variant="text-md-link" color="gray.700">
          {title}
        </Text>

        <IconButton
          aria-label={title}
          variant="unstyled"
          icon={
            <Icon
              size="20px"
              icon={isOpen ? "ms_keyboard_arrow_up" : "ms_keyboard_arrow_down"}
            />
          }
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing=".25rem"
          my="1rem">
          {items?.map((item) => (
            <Href
              key={item.id}
              href={generateFrontPath({
                id: "@front.thematics.view",
                parameters: {
                  campaignSlug: getCampaignSlug({
                    campaign: item,
                    locale,
                    defaultLocale,
                  }),
                },
              })}>
              <Text variant="text-sm">{translate(item.title)}</Text>
            </Href>
          ))}
        </SimpleGrid>
      </Collapse>
    </Box>
  );
}

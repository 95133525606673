import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import useTranslate from "../../../hooks/useTranslate";
import FormController from "../../ReactHookForm/FormControlRHF";
import FormErrorMessageRHF from "../../ReactHookForm/FormErrorMessageRHF";
import FormErrorObserverRHF from "../../ReactHookForm/FormErrorObserverRHF";
import AdvisedFormLabel from "../AdvisedFormLabel";

/**
 * @param {{
 * nameCountry: string,
 * nameNumber: string,
 * countries: import("../../../types/Country").Country[],
 * inputProps?: any,
 * placeholder?: string,
 * isRequired?: boolean,
 * isDisabled?: boolean,
 * shouldDisplayFlag?: boolean,
 * label?: string,
 * helperText?: string | import("react").ReactElement,
 * advisedText?: any,
 * rightElement?: import("react").ReactNode,
 * } & import("@chakra-ui/react").FormControlProps} props
 */
function InputPhone(props) {
  const {
    nameNumber,
    nameCountry,
    countries,
    isRequired,
    inputProps,
    placeholder,
    isDisabled,
    shouldDisplayFlag = false,
    label = undefined,
    helperText,
    advisedText,
    rightElement,
    ...otherProps
  } = props;

  const intl = useIntl();

  const translate = useTranslate();

  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      {...otherProps}>
      <FormLabel
        htmlFor="input-phone-number"
        display={advisedText ? "flex" : undefined}>
        {advisedText ? (
          <AdvisedFormLabel label={label} advisedText={advisedText} />
        ) : (
          label ??
          intl.formatMessage({
            defaultMessage: "Téléphone",
          })
        )}
      </FormLabel>

      {/*
        UI of the phone input inspired by https://evilmartians.com/chronicles/phone-inputs-and-you-the-designers-essential-ui-guide and airbnb design
      */}
      <InputGroup>
        <InputLeftAddon zIndex="auto" w={{ base: "100px", lg: "150px" }} px="0">
          <FormController
            name={nameCountry}
            defaultValue=""
            displayError={false}
            rules={{ required: isRequired }}
            render={(fields) => (
              <Select
                {...fields}
                isDisabled={isDisabled}
                variant="outline"
                borderRightRadius="none"
                borderTop="none"
                borderBottom="none"
                borderLeft="none"
                height="38px">
                <option value="">-</option>

                {countries?.map?.((country) => (
                  <option value={country.iso_code} key={country.id}>
                    {`${
                      shouldDisplayFlag
                        ? country.flag_emoji
                        : translate(country.name)
                    } (${country?.phone_prefixes?.[0] ?? ""})`}
                  </option>
                ))}
              </Select>
            )}
          />
        </InputLeftAddon>
        <FormController
          width="100%"
          name={nameNumber}
          defaultValue=""
          displayError={false}
          rules={{ required: isRequired }}
          render={(fields) => (
            <Input
              {...fields}
              id="input-phone-number"
              type="tel"
              isDisabled={isDisabled}
              placeholder={
                placeholder ??
                intl.formatMessage({
                  defaultMessage:
                    "Numéro de téléphone sans l'indicatif de pays",
                })
              }
              {...inputProps}
              borderLeftRadius="none"
            />
          )}
        />

        {rightElement && rightElement}
      </InputGroup>
      {helperText && (
        <>
          {typeof helperText === "object" ? (
            helperText
          ) : (
            <FormHelperText>{helperText}</FormHelperText>
          )}
        </>
      )}

      <FormErrorObserverRHF
        name={nameCountry}
        render={({ error, hasError }) =>
          hasError && <FormErrorMessageRHF error={error} />
        }
      />

      <FormErrorObserverRHF
        name={nameNumber}
        render={({ error, hasError }) =>
          hasError && <FormErrorMessageRHF error={error} />
        }
      />
    </FormControl>
  );
}

export default InputPhone;

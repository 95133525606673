import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useBreakpointValue,
  Wrap,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import {
  amenitiesCategoryMessage,
  AMENITIES_CATEGORY_LIST,
  AMENITIES_CATEGORY_VALUE_CHARGING_STATION,
} from "@raiden/library-ui/constants/amenities";
import {
  HOTELS_PAYMENT_MODES_VALUE_EANCV,
  HOTELS_PAYMENT_MODES_VALUE_LIST,
  HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER,
} from "@raiden/library-ui/constants/hotels";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AmenitiesPicker } from "../../../components/Form/AmenitiesPicker";
import { NumberPicker } from "../../../components/Form/NumberPicker";
import { OtaTypesPicker } from "../../../components/Form/OtaTypesPicker";
import { PaymentsPicker } from "../../../components/Form/PaymentsPicker";
import { RankingPicker } from "../../../components/Form/RankingPicker";
import { getSearchFormDefaultValues } from "../Search/Handler";
import { Section } from "./Section";

/** @type {import("react-hook-form").FieldPath<import("../Search/Handler").SearchFormValues>[]} */
export const SEARCH_FILTERS_NAMES = [
  "min_price",
  "max_price",
  "label_ranking",
  "bedrooms",
  "beds",
  "bathrooms",
  "bookable_online",
  "allow_pets",
  "last_minute",
  "promotion",
  "highlight",
  "new",
  "amenity_ids",
  "payment_modes",
  "oack_mode",
  "ota_types",
  "is_leisure_home",
];

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("../Search/Handler").SearchFormValues>} form
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {import("swr").SWRResponse<import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse<import("@raiden/library-ui/types/AmenityDictionaryItem").AmenityDictionaryItem[]>>} swrResponseAmenities
 * @property {import("@raiden/library-ui/types/Campaign").Campaign} [campaign]
 * @property {import("@raiden/library-ui/types/Region").Region} [region]
 * @property {import("@raiden/library-ui/types/Departement").Departement} [department]
 * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaOtaTypes} [metaOtaTypes]
 * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaAmenities} [metaAmenities]
 * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaLeisureHome} [metaLeisureHome]
 */
/**
 * @param {Props} props
 */
export function SearchFiltersModal({
  form,
  isOpen,
  onClose,
  swrResponseAmenities,
  campaign,
  region,
  department,
  metaOtaTypes,
  metaAmenities,
  metaLeisureHome,
}) {
  const intl = useIntl();

  const { configuration } = useConfiguration();

  const [isReset, setIsReset] = useState(false);

  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { control, resetField, getValues } = form;

  const handleReset = useCallback(() => {
    const defaultValues = getSearchFormDefaultValues({
      campaign,
      region,
      department,
    });
    SEARCH_FILTERS_NAMES.forEach((name) => {
      resetField(name, {
        defaultValue: defaultValues[name],
      });
    });
    setIsReset(true);
  }, [campaign, department, region, resetField]);

  function handleChangePets(event) {
    form.setValue(event.target.name, event.target.checked, {
      shouldDirty: true,
    });
    const { name, checked } = event.target;
    if (name === "allow_pets" && checked) {
      form.setValue("disallow_pets", false, { shouldDirty: true });
    } else if (name === "disallow_pets" && checked) {
      form.setValue("allow_pets", false, { shouldDirty: true });
    }
  }

  useEffect(() => {
    if (isReset) {
      const timeout = setTimeout(() => {
        setIsReset(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [isReset]);

  const handleKeyDown = useCallback(
    (evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault(),
    [],
  );

  const amenitiesDataSet = useMemo(() => {
    if (swrResponseAmenities.data) {
      return swrResponseAmenities.data.data.reduce((acc, amenity) => {
        if (amenity.is_searchable) {
          acc[amenity.category] = acc[amenity.category] || [];
          acc[amenity.category].push(amenity);
        }
        return acc;
      }, {});
    }
    return [];
  }, [swrResponseAmenities.data]);

  /** @type {import("@raiden/library-ui/types/ota/Type").OtaType[]} */
  const otaTypeToDisplay = useMemo(() => {
    let result = [];
    if (metaOtaTypes && configuration?.ota_types) {
      result = configuration.ota_types.filter(
        (otaType) => metaOtaTypes[otaType.id],
      );
    }
    return result;
  }, [configuration, metaOtaTypes]);

  // Remove amenities that are not available anymore
  useEffect(() => {
    if (swrResponseAmenities.data) {
      const currentAmenityIds = getValues("amenity_ids");
      let shouldUpdate = false;
      const updatedAmenityIds = currentAmenityIds.filter((amenityId) => {
        const found = swrResponseAmenities.data?.data.find(
          (amenity) => amenity.id === amenityId,
        );
        if (!found) {
          shouldUpdate = true;
        }
        return found;
      });
      if (shouldUpdate) {
        form.setValue("amenity_ids", updatedAmenityIds, { shouldDirty: true });
      }
    }
  }, [form, getValues, swrResponseAmenities.data]);

  const handleSearch = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    onClose();
  }, [onClose]);

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered={!isMobile}
      size={isMobile ? "full" : "4xl"}>
      <ModalOverlay />

      <ModalContent
        mx={{ base: "0", lg: "1rem" }}
        sx={{ "&": { minH: "100dvh" } }}>
        <ModalHeader borderBottomWidth="1px">
          <HStack>
            <Text variant="h4" textAlign="center" flexGrow={1}>
              <FormattedMessage defaultMessage="Filtres" />
            </Text>

            <IconButton
              icon={<Icon icon="ms_close" />}
              onClick={onClose}
              variant="outline"
              borderRadius="full"
              aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
            />
          </HStack>
        </ModalHeader>

        <ModalBody py="1.5rem">
          <Stack spacing="1.5rem" divider={<Box />}>
            <Section
              title={intl.formatMessage({
                defaultMessage: "Fourchette de prix",
              })}>
              <RGrid>
                <FormControlRHF
                  name="min_price"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "min_price",
                    ) !== undefined
                  }
                  label={intl.formatMessage({
                    defaultMessage: "Prix minimum du séjour",
                  })}
                  renderWithFormControl={(field) => (
                    <InputGroup>
                      <Input
                        type="number"
                        {...field}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                        placeholder={intl.formatMessage({
                          defaultMessage: "Prix minimum",
                        })}
                      />

                      <InputRightAddon
                        opacity={
                          campaign?.attributes?.find(
                            (attr) => attr.name === "min_price",
                          ) !== undefined
                            ? 0.5
                            : 1
                        }>
                        <Icon icon="ms_euro" />
                      </InputRightAddon>
                    </InputGroup>
                  )}
                />

                <FormControlRHF
                  name="max_price"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "max_price",
                    ) !== undefined
                  }
                  label={intl.formatMessage({
                    defaultMessage: "Prix maximum du séjour",
                  })}
                  renderWithFormControl={(field) => (
                    <InputGroup>
                      <Input
                        type="number"
                        {...field}
                        onKeyDown={handleKeyDown}
                        autoComplete="off"
                        placeholder={intl.formatMessage({
                          defaultMessage: "Prix maximum",
                        })}
                      />

                      <InputRightAddon
                        opacity={
                          campaign?.attributes?.find(
                            (attr) => attr.name === "max_price",
                          ) !== undefined
                            ? 0.5
                            : 1
                        }>
                        <Icon icon="ms_euro" />
                      </InputRightAddon>
                    </InputGroup>
                  )}
                />
              </RGrid>
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Niveau de confort",
              })}>
              <FormControlRHF
                control={control}
                name="label_ranking"
                isDisabled={
                  campaign?.attributes?.find(
                    (attr) => attr.name === "min_price",
                  ) !== undefined
                }
                render={(field) => <RankingPicker {...field} />}
              />
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Nombre de chambre",
              })}>
              <FormControlRHF
                control={control}
                name="bedrooms"
                isDisabled={
                  campaign?.attributes?.find(
                    (attr) => attr.name === "chambers",
                  ) !== undefined
                }
                render={(field) => <NumberPicker {...field} />}
              />
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Nombre de lits",
              })}>
              <FormControlRHF
                control={control}
                name="beds"
                isDisabled={
                  campaign?.attributes?.find((attr) => attr.name === "beds") !==
                  undefined
                }
                render={(field) => <NumberPicker {...field} />}
              />
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Nombre de salle de bain",
              })}>
              <FormControlRHF
                control={control}
                name="bathrooms"
                isDisabled={
                  campaign?.attributes?.find(
                    (attr) => attr.name === "bathrooms",
                  ) !== undefined
                }
                render={(field) => <NumberPicker {...field} />}
              />
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Animaux",
              })}>
              <>
                <FormControlRHF
                  control={control}
                  name="allow_pets"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "allow_pets",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-allow_pets-true"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                          onChange: handleChangePets,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel
                        htmlFor="switch-allow_pets-true"
                        cursor="pointer">
                        <FormattedMessage defaultMessage="Uniquement les hébergements acceptant les animaux" />
                      </FormLabel>
                    </FormControl>
                  )}
                />

                <FormControlRHF
                  control={control}
                  name="disallow_pets"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "allow_pets",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-allow_pets-false"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                          onChange: handleChangePets,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel
                        htmlFor="switch-allow_pets-false"
                        cursor="pointer">
                        <FormattedMessage defaultMessage="Uniquement les hébergements refusant les animaux" />
                      </FormLabel>
                    </FormControl>
                  )}
                />
              </>
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Options de réservation",
              })}>
              <Wrap spacing="1rem">
                <FormControlRHF
                  control={control}
                  name="bookable_online"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "bookable_online",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-bookable_online"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel
                        htmlFor="switch-bookable_online"
                        cursor="pointer">
                        <FormattedMessage defaultMessage="Uniquement les hébergements réservable en ligne" />
                      </FormLabel>
                    </FormControl>
                  )}
                />

                <FormControlRHF
                  control={control}
                  name="oack_mode"
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-oack_mode"
                        {...{
                          name: field.name,
                          ref: field.ref,
                          isChecked:
                            field.value ===
                            HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER,
                          onChange: (e) => {
                            field.onChange(
                              e.target.checked
                                ? HOTEL_OWNER_ACKNOWLEDGMENT_MODE_VALUE_NEVER
                                : null,
                            );
                          },
                        }}
                        mr=".5rem"
                      />

                      <FormLabel htmlFor="switch-oack_mode" cursor="pointer">
                        <FormattedMessage defaultMessage="Réservation instantanée" />
                      </FormLabel>
                    </FormControl>
                  )}
                />
              </Wrap>
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Offres spéciales",
              })}>
              <Wrap spacing="1rem">
                <FormControlRHF
                  control={control}
                  name="last_minute"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "last_minute",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-last_minute"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel htmlFor="switch-last_minute" cursor="pointer">
                        <FormattedMessage defaultMessage="Dernière minute" />
                      </FormLabel>
                    </FormControl>
                  )}
                />

                <FormControlRHF
                  control={control}
                  name="promotion"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "promotion",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-promotion"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel htmlFor="switch-promotion" cursor="pointer">
                        <FormattedMessage defaultMessage="Offre du moment / Promo" />
                      </FormLabel>
                    </FormControl>
                  )}
                />
              </Wrap>
            </Section>

            <Section
              title={intl.formatMessage({
                defaultMessage: "Tendance",
              })}>
              <Wrap spacing="1rem">
                <FormControlRHF
                  control={control}
                  name="highlight"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "highlight",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-highlight"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel htmlFor="switch-highlight" cursor="pointer">
                        <FormattedMessage defaultMessage="Coup de coeur" />
                      </FormLabel>
                    </FormControl>
                  )}
                />

                <FormControlRHF
                  control={control}
                  name="new"
                  isDisabled={
                    campaign?.attributes?.find(
                      (attr) => attr.name === "new",
                    ) !== undefined
                  }
                  render={(field) => (
                    <FormControl display="flex">
                      <Switch
                        id="switch-new"
                        {...{
                          ...field,
                          value: undefined,
                          isChecked: field.value,
                        }}
                        mr=".5rem"
                      />

                      <FormLabel htmlFor="switch-new" cursor="pointer">
                        <FormattedMessage defaultMessage="Nouveautés" />
                      </FormLabel>
                    </FormControl>
                  )}
                />
              </Wrap>
            </Section>

            {metaAmenities &&
              AMENITIES_CATEGORY_LIST.filter(
                (amenitiesCategory) =>
                  (amenitiesDataSet[amenitiesCategory.id]?.length ?? 0) > 0,
              )
                .filter((amenitiesCategory) =>
                  amenitiesDataSet[amenitiesCategory.id].some((amenity) =>
                    metaAmenities.includes(amenity.id),
                  ),
                )
                .map((amenitiesCategory) => (
                  <Section
                    key={amenitiesCategory.id}
                    title={intl.formatMessage(amenitiesCategoryMessage, {
                      category:
                        amenitiesCategory.id ===
                        AMENITIES_CATEGORY_VALUE_CHARGING_STATION
                          ? "charging_station"
                          : amenitiesCategory.id,
                    })}>
                    <FormControlRHF
                      control={control}
                      name="amenity_ids"
                      isDisabled={campaign?.attributes?.some(
                        (attr) => attr.name === "bookable_online",
                      )}
                      render={(field) => (
                        <AmenitiesPicker
                          amenities={amenitiesDataSet[amenitiesCategory.id]}
                          metaAmenities={metaAmenities}
                          {...field}
                        />
                      )}
                    />
                  </Section>
                ))}

            {metaLeisureHome &&
              (otaTypeToDisplay.length > 0 || metaLeisureHome > 0) && (
                <Section
                  title={intl.formatMessage({
                    defaultMessage: "Type d'hébergement",
                  })}>
                  <FormControlRHF
                    control={control}
                    name="ota_types"
                    render={(field) => (
                      <Wrap>
                        <OtaTypesPicker
                          otaTypes={otaTypeToDisplay}
                          {...field}
                        />

                        <FormControlRHF
                          control={control}
                          name="is_leisure_home"
                          render={(field) => (
                            <Button
                              variant="outline"
                              borderRadius="full"
                              onClick={() => {
                                field.onChange({
                                  target: {
                                    name: field.name,
                                    value: !field.value,
                                  },
                                });
                              }}
                              colorScheme={
                                field.value ? "brandSecondary" : undefined
                              }>
                              <Text variant="text-xs" color="inherit">
                                {intl.formatMessage({
                                  defaultMessage:
                                    "Habitation légère de loisirs",
                                })}
                              </Text>
                            </Button>
                          )}
                        />
                      </Wrap>
                    )}
                  />
                </Section>
              )}

            <Section
              title={intl.formatMessage({
                defaultMessage: "Moyen de paiement",
              })}>
              <FormControlRHF
                control={control}
                name="payment_modes"
                render={(field) => (
                  <PaymentsPicker
                    {...field}
                    paymentModesList={HOTELS_PAYMENT_MODES_VALUE_LIST.filter(
                      (paymentMode) =>
                        paymentMode.id !== HOTELS_PAYMENT_MODES_VALUE_EANCV,
                    )}
                  />
                )}
              />
            </Section>
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Wrap flexGrow={1} justify="space-between">
            <Button
              onClick={handleReset}
              variant="outline"
              leftIcon={
                <Box
                  transform={isReset ? "rotate(360deg)" : "rotate(0deg)"}
                  transition="all .25s ease"
                  color={isReset ? "green" : undefined}>
                  <Icon icon={isReset ? "ms_check" : "ms_refresh"} />
                </Box>
              }>
              <Text as="span" variant="button-md">
                <FormattedMessage defaultMessage="Tout réinitialiser" />
              </Text>
            </Button>

            <Button
              ref={ref}
              onClick={handleSearch}
              leftIcon={<Icon icon="ms_search" />}
              colorScheme="brandSecondary">
              <Text as="span" variant="button-md" color="inherit">
                <FormattedMessage defaultMessage="Rechercher" />
              </Text>
            </Button>
          </Wrap>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

import { Button, HStack, Text, Wrap } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { forwardRef, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";

export const AmenitiesPicker = forwardRef(
  /**
   * @typedef {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<number[]>} FieldProps
   */
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/AmenityDictionaryItem").AmenityDictionaryItem[]} amenities
   * @property {import("@raiden/library-ui/types/SearchHotel").SearchHotelMetaAmenities} [metaAmenities]
   */
  /**
   * @param {FieldProps & Props} props
   */
  function AmenitiesPicker(
    { amenities, metaAmenities, name, value, onChange, isDisabled },
    ref,
  ) {
    const translate = useTranslate();

    const [shouldRenderAllAmenities, setShouldRenderAllAmenities] =
      useState(false);

    const amenitiesToDisplay = useMemo(() => {
      let filteredAmenities = amenities;

      if (metaAmenities && metaAmenities.length > 0) {
        filteredAmenities = amenities.filter((amenity) =>
          metaAmenities.includes(amenity.id),
        );
      }

      return filteredAmenities
        .slice()
        .sort((a, b) => {
          const amenityA = translate(a.name) ?? "";
          const amenityB = translate(b.name) ?? "";
          return amenityA.localeCompare(amenityB);
        })
        .filter((_, index) => index < 10 || shouldRenderAllAmenities);
    }, [amenities, shouldRenderAllAmenities, translate, metaAmenities]);

    return (
      <Wrap>
        {amenitiesToDisplay.map((amenity) => (
          <Button
            key={amenity.id}
            ref={ref}
            onClick={() =>
              onChange({
                target: {
                  name,
                  value: value.includes(amenity.id)
                    ? value.filter((id) => id !== amenity.id)
                    : [...value, amenity.id],
                },
              })
            }
            isDisabled={isDisabled}
            colorScheme={
              value.includes(amenity.id) ? "brandSecondary" : undefined
            }
            size="sm"
            borderRadius="full"
            minW="2.5rem"
            h="2.5rem"
            variant="outline">
            <HStack spacing="8px">
              <Icon icon={amenity.icon ?? "ms_star"} size="1rem" />

              <Text variant="text-xs" color="inherit">
                {capitalize(translate(amenity.name))}
              </Text>
            </HStack>
          </Button>
        ))}

        {amenitiesToDisplay.length > 10 && !shouldRenderAllAmenities && (
          <Button
            onClick={() => setShouldRenderAllAmenities((prev) => !prev)}
            variant="link"
            size="sm"
            h="2.5rem"
            colorScheme="brandSecondary">
            <FormattedMessage defaultMessage="Tout afficher" />
          </Button>
        )}
      </Wrap>
    );
  },
);

import { Button, Text, Wrap } from "@chakra-ui/react";
import {
  hotelsPaymentModeMessage,
  HOTELS_PAYMENT_MODES_VALUE_LIST,
} from "@raiden/library-ui/constants/hotels";
import { forwardRef } from "react";
import { useIntl } from "react-intl";

export const PaymentsPicker = forwardRef(
  /**
   * @typedef {object} Props
   * @property {Array<{ id: string }>} [paymentModesList]
   */
  /**
   * @typedef {import("@raiden/library-ui/components/ReactHookForm/FormControlRHF").FieldProps<string[]>} FieldProps
   */
  /**
   * @param {FieldProps & Props} props
   */
  function PaymentsPicker(
    {
      name,
      value,
      onChange,
      isDisabled,
      paymentModesList = HOTELS_PAYMENT_MODES_VALUE_LIST,
    },
    ref,
  ) {
    const intl = useIntl();

    const sortedPaymentModes = paymentModesList.sort((a, b) => {
      const modeA = intl.formatMessage(hotelsPaymentModeMessage, {
        mode: a.id,
      });
      const modeB = intl.formatMessage(hotelsPaymentModeMessage, {
        mode: b.id,
      });
      return modeA.localeCompare(modeB);
    });

    return (
      <Wrap>
        {sortedPaymentModes.map((paymentMode) => (
          <Button
            ref={ref}
            key={paymentMode.id}
            variant="outline"
            isDisabled={isDisabled}
            onClick={() => {
              onChange({
                target: {
                  name,
                  value: value.includes(paymentMode.id)
                    ? value.filter((id) => id !== paymentMode.id)
                    : [...value, paymentMode.id],
                },
              });
            }}
            borderRadius="full"
            colorScheme={
              value.includes(paymentMode.id) ? "brandSecondary" : undefined
            }>
            <Text variant="text-xs" color="inherit">
              {intl.formatMessage(hotelsPaymentModeMessage, {
                mode: paymentMode.id,
              })}
            </Text>
          </Button>
        ))}
      </Wrap>
    );
  },
);
